import React, { memo, useEffect, useState, useCallback } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Layout
import DocumentsLayout from '../../../layouts/DocumentsLayout'
// Component
import PageTitle from '../../../components/PageTitle'
import AdvanceFilterBlock from './AdvanceFilterBlock'
import UnreadText from '../../../components/UnreadText/UnreadText'
// Style Component
import {
  SearchBar,
  TableFooterActionWrapper,
} from './DiNoiBoPageStyled'
import { FilterButtonWrapper } from '../InternalDocumentIncomingDocPage/InternalDocumentIncomingDocPageStyled'
// Ant design
import {
  Button,
  Empty,
  Input,
  Pagination,
  Space,
  Row,
  Col,
  message,
} from 'antd'
import {
  CloseCircleOutlined,
  FilterOutlined,
  PlusOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
// Other
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import utils from '../../../utils'
import moment from 'moment'
import { ACL_ACTION_TYPE, DATE_FORMAT_DEFAULT, DOCUMENT_TYPE } from '../../../constants'
import TableComponent from '../../../components/Common/TableComponent'
import { EmptyText } from '../../../components/Common/CellText'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import AccessControlAction from '../../../components/AccessControlAction'

const { Search } = Input

const DiNoiBoPage = props => {
  const {
    history,
    internalDocumentStore,
    loadingAnimationStore,
    bookStore,
    authorityStore,
  } = props

  const {
    internalDocumentOutgoingList,
    internalDocumentOutgoingListTotalCount,
    queryParamsOutgoing,
  } = internalDocumentStore

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [isRefreshPage, setIsRefreshPage] = useState(true)

  const NGAY_DI_WIDTH = 100
  const SO_HIEU_WIDTH = 100
  const TRICH_YEU_WIDTH = 400
  const CO_QUAN_BAN_HANH_WIDTH = 250
  const NGUOI_KY_WIDTH = 150
  const SCROLL_WIDTH = 1000

  const tableColumns = [
    {
      title: 'Ngày đi',
      width: NGAY_DI_WIDTH,
      render: record => (
        <UnreadText
          text={moment(record.outgoing_date).format(DATE_FORMAT_DEFAULT)}
          isUnread={checkUnread(record)}
          width={NGAY_DI_WIDTH}
        />
      ),
    },
    {
      title: 'Số hiệu',
      width: SO_HIEU_WIDTH,
      render: record => (
        <UnreadText
          text={record.document_number}
          isUnread={checkUnread(record)}
          width={SO_HIEU_WIDTH}
        />
      ),
    },
    {
      title: 'Trích yếu',
      width: TRICH_YEU_WIDTH,
      render: record => (
        <UnreadText
          text={record.title}
          isUnread={checkUnread(record)}
          width={TRICH_YEU_WIDTH}
        />
      ),
    },
    {
      title: 'Cơ quan ban hành',
      width: CO_QUAN_BAN_HANH_WIDTH,
      render: record => (
        <UnreadText
          text={record.authority_issued}
          isUnread={checkUnread(record)}
          width={CO_QUAN_BAN_HANH_WIDTH}
        />
      ),
    },
    {
      title: 'Người ký',
      width: NGUOI_KY_WIDTH,
      render: record =>
        record.signer ? (
          <UnreadText
            text={record.signer}
            isUnread={checkUnread(record)}
            width={NGUOI_KY_WIDTH}
          />
        ) : (
          <EmptyText>Không có.</EmptyText>
        ),
    },
  ]

  const checkUnread = record => record.status_doc_outgoing === 'UNREAD'

  const exportDocument = useCallback(async () => {
    const col = [
      'Ngày đi',
      'Số hiệu',
      'Trích yếu',
      'Cơ quan ban hành',
      'Người ký',
    ]

    let rows = []
    rows.push(col)

    try {
      setExportLoading(true)
      const res = await internalDocumentStore.getInternalDocumentOutgoingListAll()

      if (res && res.data && res.data.data) {
        res.data.data.map(item => {
          rows.push([
            moment(item.outgoing_date).format(DATE_FORMAT_DEFAULT),
            item.document_number,
            item.title,
            item.authority_issued,
          ])
        })
      }
      utils.exportExcel(
        rows,
        'VBNB - văn bản đi - ' + moment().format('YYYY-MM-DD hmms')
      )
      internalDocumentStore.setQueryParamsOutgoing({
        ...queryParamsOutgoing,
        ...queryStringParse,
      })
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setExportLoading(false)
    }
  }, [queryParamsOutgoing])

  const handleQuickSearch = title => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      title: title && title.trim(),
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: 0,
        size: 10,
        title: title ? title.trim() : null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(
      `/internal-document/di-noi-bo?${queryStringStringify}`
    )
  }

  const onChangePagination = pageIndex => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: pageIndex - 1,
      size: 10,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: pageIndex - 1,
        size: 10,
      },
      {
        skipNull: true,
      }
    )
    history.replace(
      `/internal-document/di-noi-bo?${queryStringStringify}`
    )
  }

  const getInternalDocumentOutgoingList = async () => {
    try {
      loadingAnimationStore.setTableLoading(true)
      await internalDocumentStore.getInternalDocumentOutgoingList()
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    queryParamsOutgoing.book_group_id = 3

    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      ...queryStringParse,
    })
    ;(async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        authorityStore.setAuthorityIssuedsQueryParams({
          type: DOCUMENT_TYPE.OUTGOING,
          key: null,
        })
        await Promise.all([
          authorityStore.getAuthorityIssueds(),
          bookStore.getBookGroup('DI'),
          internalDocumentStore.getInternalDocumentOutgoingList(),
        ])
        setIsRefreshPage(false)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
    return () => {
      internalDocumentStore.clearInternalDocumentOutgoingList()
      bookStore.clearBookGroupList()
      internalDocumentStore.clearQueryParamsOutgoing()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
      ;(async () => {
      await getInternalDocumentOutgoingList()
    })()
  }, [queryParamsOutgoing])

  useEffect(() => {
    // Đếm số filter hiện tại
    let count = 0
    if (queryStringParse.title) {
      count++
    }
    if (queryStringParse.from_date_issued && queryStringParse.to_date_issued) {
      count++
    }
    if (queryStringParse.document_type) {
      count++
    }
    if (queryStringParse.document_number) {
      count++
    }
    if (queryStringParse.urgency_level) {
      count++
    }
    if (queryStringParse.signer) {
      count++
    }
    if (queryStringParse.book_group_id) {
      count++
    }
    if (queryStringParse.book_id) {
      count++
    }
    if (queryStringParse.authority_name) {
      count++
    }
    setCountFilter(count)
  }, [queryStringParse])

  return (
    <DocumentsLayout
      title={
        <PageTitle location={props.location} title="Văn bản đi" hiddenGoBack>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__OUTGOING}>
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                history.push('/internal-document/di-noi-bo/create')
              }
              type={'primary'}>
              Tạo mới văn bản đi
            </Button>
          </AccessControlAction>
        </PageTitle>
      }
      selectedKey="di-noi-bo"
      linkToIncomingDocument="/internal-document/incoming-document"
      linkToOutgoingDocument="/internal-document/outgoing-document"
      linkToDiNoiBo="/internal-document/di-noi-bo"
      linkToSignDocument="/internal-document/sign-document"
      linkToConfigDocument="/internal-document/config-document/document-books">
      <Helmet>
        <title>Văn bản đi | Nghệ Tĩnh Quản lý VB Nghệ Tĩnh</title>
      </Helmet>
      <div>
        <Row>
          <Col span={12}>
            {!showAdvanceFilter ? (
              <SearchBar>
                <Search
                  allowClear
                  onSearch={value => handleQuickSearch(value)}
                  defaultValue={queryStringParse.title}
                  placeholder={'Tìm kiếm văn bản theo Trích yếu / Số hiệu'}
                />
              </SearchBar>
            ) : null}
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <FilterButtonWrapper>
              <span
                className={'count-filter'}
                style={{ display: !countFilter && 'none' }}>
                {countFilter}
              </span>
              <Button
                type={showAdvanceFilter ? 'primary' : 'default'}
                ghost={showAdvanceFilter}
                style={{ marginBottom: showAdvanceFilter ? 10 : 0 }}
                onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}>
                {showAdvanceFilter ? (
                  <Space>
                    <CloseCircleOutlined />
                    Ẩn tìm kiếm nâng cao
                  </Space>
                ) : (
                  <Space>
                    <FilterOutlined />
                    Tìm kiếm nâng cao
                  </Space>
                )}
              </Button>
            </FilterButtonWrapper>
            <Button
              loading={exportLoading}
              onClick={exportDocument}
              style={{ marginLeft: '10px' }}>
              <PrinterOutlined />
              In
            </Button>
          </Col>
        </Row>
        {showAdvanceFilter ? <AdvanceFilterBlock /> : null}
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(
                  `/internal-document/di-noi-bo/view/${record.code}`
                ),
            }
          }}
          rowKey={record => record.id}
          dataSource={internalDocumentOutgoingList}
          columns={tableColumns}
          pagination={false}
          scroll={{ x: SCROLL_WIDTH }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          loading={{
            tip: 'Đang tải văn bản...',
            spinning: loadingAnimationStore.tableLoading,
          }}
        />
        <TableFooterActionWrapper>
          <Pagination
            onChange={e => onChangePagination(e)}
            pageSize={queryParamsOutgoing.size}
            showSizeChanger={false}
            showLessItems
            current={queryParamsOutgoing.page + 1}
            total={internalDocumentOutgoingListTotalCount}
            hideOnSinglePage={true}
          />
        </TableFooterActionWrapper>
      </div>
    </DocumentsLayout>
  )
}

DiNoiBoPage.propTypes = {}

export default memo(
  withRouter(
    inject(
      'internalDocumentStore',
      'loadingAnimationStore',
      'authorityStore',
      'bookStore',
      'selectUserStore'
    )(observer(DiNoiBoPage))
  )
)
