import styled from 'styled-components'
import { blue } from '../../color'
import { Slider } from 'antd'

export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`

export const TaskInfoList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt,
  dd {
    padding: 0.5rem 0;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  dt {
    width: 125px;
    font-weight: 700;

    &:after {
      display: inline-block;
      content: ':';
    }

    &:nth-last-child(2) {
      border-bottom: none;
    }
  }

  .no-two-dots {
    &:after {
      display: inline-block;
      content: '';
    }
  }

  dd {
    width: calc(100% - 130px);
    word-break: break-all;

    &:last-child {
      border-bottom: none;
    }
  }
  .ant-upload-list-item-info {
    cursor: pointer;
  }
`

export const SliderWrapper = styled(Slider)`
  .ant-slider-track {
    background-color: ${props => (props.completed ? '#52c41a' : blue)};
  }
`

export const FileList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    padding-right: 8px;
    cursor: pointer;

    .delete-btn {
      color: #595f67;
      opacity: 0;
      cursor: pointer;
      padding: 2px 3px;
      &:hover {
        color: red;
      }
    }
    &:hover {
      .delete-btn {
        opacity: 1;
      }
      transition: 0.3s;
    }
  }
`
export const DocumentList = styled.ul`
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  word-break: normal;
  white-space: pre-line;
  li {
    cursor: pointer;
    margin-bottom: 5px;
    &:hover {
      color: ${blue};
      text-decoration: underline;
    }
  }
`

export const HeadingButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-style {
    color: #1890ff;
    width: 32px;
    height: 32px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 5px;
    background: rgb(255, 255, 255);
    cursor: pointer;

    &:hover {
      transition: 0.2s;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      opacity: 0.9;
    }
`

export const TaskContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const BlockLeft = styled.div`
  width: calc(100% - 370px);
  @media screen and (max-width: 1299px) {
    width: 100%;
  }
`
export const BlockRight = styled.div`
  width: 350px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 1299px) {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      margin-right: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`
