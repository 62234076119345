import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import PageTitle from '../../components/PageTitle'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import DrawerWork from './DrawerWork'
import PopupWorkForm from './PopupWorkForm'
import PopupUpdateWork from './PopupUpdateWork/PopupUpdateWork'
import PopupAddPersonWork from './PopupAddPersonWork'
// Style Component
// Ant design
import { Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import ListWork from './ListWork/ListWork'
import queryString from 'query-string'
import selectPeopleStore from '../../stores/selectPeopleStore'
import { useLocation } from 'react-router-dom'
import PopupElectronicReleaseForm from './PopupElectronicReleaseForm'
import utils from '../../utils'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'
import { WorkPageWrapper } from './WorkStyledPage'

const WorkPage = props => {
  const {
    history,
    workStore,
    loadingAnimationStore,
    notificationStore,
    match,
  } = props

  const { queryParams } = workStore

  const location = useLocation()
  const queryStringParsed = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  let { code_work } = match.params
  const [isVisibleDrawerWork, setIsVisibleDrawerWork] = useState(false)
  const [isVisiblePopupWorkForm, setIsVisiblePopupWorkForm] = useState(false)
  const [isVisiblePopupUpdateWork, setIsVisiblePopupUpdateWork] = useState(
    false
  )
  const [isVisibleAddPersonWork, setIsVisibleAddPersonWork] = useState(false)
  const [
    isVisiblePopupElectronicRelease,
    setIsVisiblePopupElectronicRelease,
  ] = useState(false)

  const fetchDetails = async () => {
    if (code_work) {
      setIsVisibleDrawerWork(true)
      const parentId = utils.getParameterByName('parent_id')
      loadingAnimationStore.setShowSpinInline(true)
      try {
        if (parentId) {
          await workStore.getListSubWork(parentId)
        }
        await workStore.getWorkDetail(code_work)
      } catch (err) {
        history.push('/works')
        setIsVisibleDrawerWork(false)
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra')
      } finally {
        loadingAnimationStore.setShowSpinInline(false)
      }
    }
  }

  useEffect(() => {
    ;(queryStringParsed.document_code || queryStringParsed.mission_code) &&
      setIsVisiblePopupWorkForm(true)
    if (workStore.workList.length === 0) {
      workStore.setQueryParams({
        ...queryParams,
        ...queryStringParsed,
        assignee: queryStringParsed.assignee
          ? queryStringParsed.assignee.join('&')
          : null,
        page: 0,
      })
      ;(async () => {
        loadingAnimationStore.setTableLoading(true)
        try {
          // Ẩn lọc công việc theo tháng theo yêu cầu mới
          // workStore.setFilter('toDateWork', moment().format(ISO_DATE_FORMAT))
          // workStore.setFilter('fromDateWork', moment().subtract(30, 'd').format(ISO_DATE_FORMAT))
          await workStore.getWorkList()
          await fetchDetails()
        } catch (err) {
          console.log(err)
          message.error(err?.vi || 'Đã có lỗi xảy ra!')
        } finally {
          loadingAnimationStore.setTableLoading(false)
        }
      })()
    }

    return () => {
      selectPeopleStore.clearSelectPeopleStore()
      workStore.clearWorkStore()
      notificationStore.clearNotificationType()
      workStore.clearQueryParams()
    }
  }, [code_work])

  const onClosesDrawerWork = () => {
    history.replace('/works')
    workStore.clearWorkDetail()
    setIsVisibleDrawerWork(false)
  }

  return (
    <WorkPageWrapper isVisibleDrawerWork={isVisibleDrawerWork}>
      <DashboardLayout>
        <Helmet>
          <title>Công việc | VIMC Công việc</title>
        </Helmet>
        <PageTitle location={props.location}>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__WORK}>
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              onClick={() => {
                setIsVisiblePopupWorkForm(true)
              }}>
              Tạo công việc mới
            </Button>
          </AccessControlAction>
        </PageTitle>

        <ListWork
          code_work={code_work}
          handleOpenDrawerWork={() => setIsVisibleDrawerWork(true)}
          queryStringParsed={queryStringParsed}
        />
        {isVisibleDrawerWork && (
          <DrawerWork
            openPopupWorkForm={() => setIsVisiblePopupWorkForm(true)}
            openPopupUpdateWork={() => setIsVisiblePopupUpdateWork(true)}
            openPopupAddPersonWork={() => setIsVisibleAddPersonWork(true)}
            openPopupElectronicRelease={() =>
              setIsVisiblePopupElectronicRelease(true)
            }
            handleOpenDrawerWork={() => setIsVisibleDrawerWork(true)}
            onCloseDrawerWork={onClosesDrawerWork}
            isVisibleDrawerWork={isVisibleDrawerWork}
            queryStringParsed={queryStringParsed}
          />
        )}
        <PopupWorkForm
          queryStringParsed={queryStringParsed}
          isVisiblePopupWorkForm={isVisiblePopupWorkForm}
          handleCloseWorkForm={() => setIsVisiblePopupWorkForm(false)}
        />
        <PopupUpdateWork
          match={match}
          isVisiblePopupUpdateWork={isVisiblePopupUpdateWork}
          handleCloseUpdateWork={() => setIsVisiblePopupUpdateWork(false)}
        />
        <PopupAddPersonWork
          isVisibleAddPersonWork={isVisibleAddPersonWork}
          handleCloseAddPersonWork={() => setIsVisibleAddPersonWork(false)}
        />
        <PopupElectronicReleaseForm
          isVisiblePopupElectronicRelease={isVisiblePopupElectronicRelease}
          handleCloseElectronicRelease={() =>
            setIsVisiblePopupElectronicRelease(false)
          }
        />
      </DashboardLayout>
    </WorkPageWrapper>
  )
}

WorkPage.propTypes = {}

export default inject(
  'workStore',
  'loadingAnimationStore',
  'authenticationStore',
  'notificationStore'
)(observer(WorkPage))
