import { Tabs } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MODULE_CODE } from '../../../constants'
import DocumentManagementLayout from '../DocumentManagementLayout'
import {
  DocumentManagementTabContent,
  DocumentManagementTabLayout
} from '../DocumentManagementStyled'
const { TabPane } = Tabs

const InternalDocumentLayout = props => {
  const [activeKey, setActiveKey] = useState('document-books')
  const pathName = window.location.pathname
  const { moduleStore } = props
  const history = useHistory()
  const { moduleList } = moduleStore

  useEffect(() => {
    if (pathName.includes('authorities')) {
      setActiveKey('authorities')
    }

    if (pathName.includes('document-accessibility')) {
      setActiveKey('document-accessibility')
    }

    if (!moduleStore.checkAccessModule(MODULE_CODE.so_van_ban)) {
      setActiveKey('document-books')
    }
  }, [pathName, moduleList])

  const onChangeTabs = key => {
    history.push(`/document-management/internal-document/${key}`)
  }

  return (
    <DocumentManagementLayout selectedKey="internal-document">
      <DocumentManagementTabLayout>
          <Tabs
            tabPosition={'left'}
            activeKey={activeKey}
            onChange={onChangeTabs}
            style={{ flex: '0 0 160px' }}>
            {moduleStore.checkAccessModule(MODULE_CODE.so_van_ban) && (
              <Tabs.TabPane tab="Nhóm sổ văn bản" key="document-books" />
            )}
            {moduleStore.checkAccessModule(MODULE_CODE.co_quan_ban_hanh) && (
              <Tabs.TabPane tab="Cơ quan ban hành" key="authorities" />
            )}
            <Tabs.TabPane
              tab="Quyền xem văn bản"
              key="document-accessibility"
            />
          </Tabs>
          <DocumentManagementTabContent>
            {props.children}
          </DocumentManagementTabContent>
      </DocumentManagementTabLayout>
    </DocumentManagementLayout>
  )
}

export default inject('moduleStore')(observer(InternalDocumentLayout))
