import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Col, Form, Input, message, Row } from 'antd'
import { toJS } from 'mobx'
import accountEditStore from '../../stores/accountEditStore'

const AddAccountForm = props => {
  const {
    onCancel,
    onCloseDrawer,
    selectedAccount,
    userStore,
    loadingAnimationStore,
    authenticationStore,
  } = props
  const { selectedUser } = userStore

  const [form] = Form.useForm()

  const onFinish = values => {
    const submitValues = {
      account_name: values.account_name.trim(),
      password: values.password.trim(),
      command_code: toJS(selectedAccount).code.trim(),
      user_code: toJS(selectedUser).code.trim(),
    }

    loadingAnimationStore.showSpinner(true)
    authenticationStore
      .testLoginTruc(submitValues.account_name, submitValues.password)
      .then(async () => {
        try {
          await accountEditStore.createAccount(submitValues)
          await accountEditStore.getAccountsByUserCode(selectedUser.code)

          message.success(`Tạo tài khoản thành công!`)

          onCloseDrawer()
        } catch (e) {
          message.error(error.vi)
        } finally {
          loadingAnimationStore.showSpinner(false)
        }
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
        message.error('Tài khoản không tồn tại trên Trục liên thông!')
      })
  }

  return (
    <Form
      form={form}
      scrollToFirstError
      layout={'vertical'}
      onFinish={onFinish}
      name={'add-account-form'}>
      <Form.Item
        label={'Tên tài khoản'}
        name={'account_name'}
        rules={[{ required: true, message: 'Vui lòng nhập tên tài khoản!' }]}>
        <Input
          autoComplete={'new-password'}
          placeholder={'Nhập tên tài khoản'}
        />
      </Form.Item>
      <Form.Item
        label={'Mật khẩu'}
        name={'password'}
        hasFeedback
        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}>
        <Input.Password
          autoComplete={'new-password'}
          placeholder={'Nhập mật khẩu'}
        />
      </Form.Item>
      <Form.Item
        label="Xác nhận mật khẩu"
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          { required: true, message: 'Vui lòng xác nhận lại mật khẩu!' },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject('Mật khẩu xác nhận không trùng khớp!')
            },
          }),
        ]}>
        <Input.Password
          autoComplete={'new-password'}
          placeholder={'Xác nhận mật khẩu'}
        />
      </Form.Item>
      <Row
        type={'flex'}
        justify={'space-between'}
        gutter={15}
        style={{ marginTop: 30 }}>
        <Col span={12}>
          <Button block onClick={onCancel}>
            Huỷ
          </Button>
        </Col>
        <Col span={12}>
          <Button block type={'primary'} htmlType={'submit'}>
            Thêm tài khoản
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default inject(
  'accountEditStore',
  'userStore',
  'loadingAnimationStore',
  'authenticationStore'
)(observer(AddAccountForm))
