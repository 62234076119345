import React from 'react'
import PropTypes from 'prop-types'
// Component
import NormalTag from '../NormalTag/NormalTag'
import { EmptyText } from '../Common/CellText'
// Styled component
import { PopoverList, PopoverTitle, TagWrapper } from './PopoverWatchTimeStyled'
// Ant design
import { Popover } from 'antd'
import {
  BankOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  HistoryOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
// Other
import { blue, green, yellowPrimary } from '../../color'
import { ASSIGNEE_TYPE } from '../../constants'
import date_format from '../../date_format'

const PopoverWatchTime = props => {
  const { userList, title, showFullTag } = props

  // userList có dạng:
  // userList = [
  //   {
  //     assignee_type: "USER"
  //     full_name: "ĐỖ ĐỨC ÂN"
  //     id: "andd"
  // last_date_read
  //     permission: "LEAD"
  //   }
  //   ...
  // ]

  const popoverList = listSelected => {
    return (
      <PopoverList>
        <div className={'popover-head'}>
          <b>
            <UserOutlined style={{ color: yellowPrimary, marginRight: 6 }} />
            Người dùng
          </b>
          <b style={{ display: 'inline-block', width: 132 }}>
            <HistoryOutlined style={{ color: yellowPrimary, marginRight: 6 }} />
            Thời gian xem
          </b>
        </div>
        {listSelected &&
          listSelected.map(el => {
            let iconAssigneeType = null
            if (el.assignee_type === ASSIGNEE_TYPE.USER) {
              iconAssigneeType = (
                <UserOutlined style={{ color: blue, marginRight: 6 }} />
              )
            }
            if (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) {
              iconAssigneeType = (
                <BankOutlined style={{ color: green, marginRight: 6 }} />
              )
            }
            if (el.assignee_type === ASSIGNEE_TYPE.GROUP) {
              iconAssigneeType = (
                <TeamOutlined
                  style={{ color: yellowPrimary, marginRight: 6 }}
                />
              )
            }
            const textColor =
              el.assignee_type === ASSIGNEE_TYPE.USER
                ? blue
                : el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT
                ? green
                : yellowPrimary
            return (
              <li key={el.id}>
                <span
                  style={{
                    color: textColor,
                    textTransform: 'capitalize',
                  }}>
                  {iconAssigneeType}
                  {(el?.full_name ?? el?.name)?.toLowerCase()}
                </span>
                <time>
                  {el.last_date_read ? (
                    <span>
                      <EyeOutlined style={{ color: green, marginRight: 6 }} />
                      {date_format.HH_mm_DD_MM_YYYY(el.last_date_read)}
                    </span>
                  ) : (
                    <EmptyText>
                      <EyeInvisibleOutlined
                        style={{ color: '#ccc', marginRight: 6 }}
                      />
                      Chưa xem.
                    </EmptyText>
                  )}
                </time>
              </li>
            )
          })}
      </PopoverList>
    )
  }

  return (
    <Popover
      placement="top"
      content={() => popoverList(userList)}
      title={<PopoverTitle>{title}</PopoverTitle>}
      trigger="click">
      <TagWrapper>
        <NormalTag
          noHover={true}
          showFullTag={showFullTag}
          tags={userList.map(user =>
            (user?.full_name ?? user?.name)?.toLowerCase()
          )}
        />
      </TagWrapper>
    </Popover>
  )
}

PopoverWatchTime.propTypes = {
  userList: PropTypes.array,
  title: PropTypes.string,
  showFullTag: PropTypes.bool,
}

export default PopoverWatchTime
