import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ConnectedCommitteeDocumentLayout from '../../layouts/ConnectedCommitteeDocumentLayout'
import { Container } from '../../layouts/Container/Container'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Switch,
  Upload,
} from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import validator from '../../validator'
import {
  DraggerUploadStyled,
  FormActionFooter,
} from './ConnectedCommitteeDocumentOutgoingDocUpdatePageStyled'
import { inject, observer } from 'mobx-react'
import EmptyContent from '../../components/EmptyContent'
import moment from 'moment'
import { DATE_FORMAT_LIST, ISO_DATE_FORMAT } from '../../constants'

const { Option } = Select
const { TextArea } = Input
const { Dragger } = Upload

const ConnectedCommitteeDocumentOutgoingDocUpdatePage = props => {
  const {
    commonStore,
    connectedCommitteeDocumentStore,
    fileStore,
    loadingAnimationStore,
    authenticationStore,
    match,
  } = props

  const { documentId } = match.params
  const [form] = Form.useForm()

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore
      .getOutgoingCommitteeDocumentById(documentId)
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => {
      form.resetFields()
      connectedCommitteeDocumentStore.clearSelectedOutgoingCommitteeDocument()
    }
  }, [])

  const {
    selectedOutgoingCommitteeDocument,
    selectedOutgoingCommitteeDocumentAttachment,
  } = connectedCommitteeDocumentStore
  const {
    attachments,
    code,
    history,
    receiver_organ_id,
    receiver_organ_name,
    sender_organ_id,
    sender_organ_name,
    sender_sent_time,
    status,
    title,
  } = selectedOutgoingCommitteeDocument

  const { currentUser } = authenticationStore

  const { isEOfficeLeader, isEOfficeClerical } = authenticationStore

  const handleCreateOutgoingDocument = submitData => {
    connectedCommitteeDocumentStore
      .updateOutgoingCommitteeDocument(submitData)
      .then(() => {
        handleCancelCreate()
        message.success(`Cập nhật văn bản thành công`)
      })
      .catch(error => {
        message.error(error.vi)
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const onFinish = values => {
    const {
      appendixes,
      bussiness_doc_reason,
      code_notation,
      code_number,
      competence,
      content,
      direction,
      due_date,
      file_upload,
      full_name,
      page_amount,
      place,
      position,
      priority,
      promulgation_amount,
      promulgation_date,
      sphere_of_promulgation,
      steering_type,
      subject,
      to_places,
      type,
      type_name,
      typer_notation,
    } = values

    loadingAnimationStore.showSpinner(true)

    const uploadFileList = file_upload.fileList.map(file => file.originFileObj)
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })

    Promise.all(batchUploadArr)
      .then(response => {
        const submitValues = {
          attachments: response.map(response => response.data.file_id),
          bussiness: {
            bussiness_doc_reason: bussiness_doc_reason,
            paper: '0',
          },
          message_header: {
            code: {
              code_notation: code_notation,
              code_number: code_number,
            },
            content: content ? content : '',
            document_type: {
              type: type,
              type_name: type_name ? type_name : '',
            },
            due_date: due_date ? moment(due_date).format(ISO_DATE_FORMAT) : '',
            other_info: {
              appendixes: appendixes ? [appendixes] : [],
              page_amount: page_amount ? page_amount : '',
              priority: priority,
              promulgation_amount: promulgation_amount
                ? promulgation_amount
                : '',
              sphere_of_promulgation: sphere_of_promulgation
                ? sphere_of_promulgation
                : '',
              typer_notation: typer_notation ? typer_notation : '',
            },
            promulgation_info: {
              place: place ? place : '',
              promulgation_date: moment(promulgation_date).format(
                ISO_DATE_FORMAT
              ),
            },
            signer_info: {
              competence: competence ? competence : '',
              full_name: full_name ? full_name : '',
              position: position ? position : '',
            },
            steering_type: steering_type,
            subject: subject,
            to_places: to_places ? [to_places] : [],
          },
          update_doc_id: documentId && documentId,
        }
        handleCreateOutgoingDocument(submitValues)
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }

  const handleAddFileToUpload = () => false
  const [fileList, setFileList] = useState(null)

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ file_upload: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList.filter(
      (elem, index, fileList) =>
        fileList.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleCancelCreate = () => {
    form.resetFields()
    props.history.push('/connected-committee-document/outgoing-document')
  }

  useEffect(() => {
    if (currentUser === undefined) return
    if (isEOfficeLeader || isEOfficeClerical) return
    props.history.push('/dashboard')
    message.error('Bạn không có quyền truy cập trang này!')
  }, [isEOfficeLeader, isEOfficeClerical])

  useEffect(() => {
    documentId &&
      form.setFieldsValue({
        type: '2',
        priority: '0',
        type_name: 'Công văn',
        promulgation_amount: 1,
        page_amount: 1,
        direction: false,
        paper: true,
        steering_type: '0',

        code_number: code && code.split('/')[0],
        code_notation: code && code.split('/')[1],
        promulgation_date: sender_sent_time && moment(sender_sent_time),
        subject: title,
      })
  }, [selectedOutgoingCommitteeDocument])

  return (
    <ConnectedCommitteeDocumentLayout>
      <Helmet>
        <title>Cập nhật văn bản | VIMC eOffice</title>
      </Helmet>
      <ContentBlockWrapper>
        <Container maxWidth={640}>
          <PageTitle
            location={props.location}
            title={'Cập nhật văn bản'}
            marginBottom={30}
            marginTop={30}
            color={commonStore.appTheme.solidColor}
          />
          <Form
            form={form}
            scrollToFirstError={true}
            name={'create-outgoing-document'}
            layout={'vertical'}
            onFinish={onFinish}>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Số văn bản'}
                  name={'code_number'}
                  rules={[
                    { required: true, message: 'Vui lòng nhập số văn bản!' },
                  ]}>
                  <Input maxLength={11} placeholder={'Nhập số văn bản đi'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Số hiệu'}
                  name={'code_notation'}
                  rules={[
                    {
                      required: true,
                      message: ' Vui lòng nhập số hiệu văn bản!',
                    },
                  ]}>
                  <Input maxLength={30} placeholder={'Nhập số hiệu văn bản'} />
                </Form.Item>
              </Col>
            </Row>

            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item label={'Ban hành'} name={'place'}>
                  <Input
                    maxLength={50}
                    placeholder={'Nhập cơ quan ban hành văn bản'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ngày ban hành'}
                  name={'promulgation_date'}
                  rules={[
                    {
                      required: true,
                      message: ' Vui lòng chọn ngày ban hành văn bản!',
                    },
                  ]}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT_LIST}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={'Loại văn bản'} name={'type'}>
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn loại văn bản --'}>
                <Option value={'2'}>Văn bản hành chính</Option>
                <Option value={'1'}>Văn bản quy phạm pháp luật</Option>
              </Select>
            </Form.Item>

            <Form.Item label={'Tên loại văn bản'} name={'type_name'}>
              <Input maxLength={100} placeholder={'Nhập tên loại văn bản'} />
            </Form.Item>

            <Form.Item
              label={'Tiêu đề'}
              name={'subject'}
              rules={[
                { required: true, message: ' Vui lòng nhập tiêu đề văn bản!' },
                { validator: validator.validateEmptyString },
              ]}>
              <Input maxLength={500} placeholder={'Nhập tiêu đề văn bản'} />
            </Form.Item>

            <Form.Item label={'Nội dung văn bản'} name={'content'}>
              <TextArea
                rows={4}
                placeholder={
                  'Nhập nội dung văn bản, chỉ áp dụng đối với văn bản dạng phi cấu trúc có độ dài không quá 500 ký tự'
                }
              />
            </Form.Item>

            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item label={'Thẩm quyền'} name={'competence'}>
                  <Input maxLength={100} placeholder={'Nhập thẩm quyền'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label={'Người ký'} name={'full_name'}>
                  <Input maxLength={50} placeholder={'Nhập tên người ký'} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={'Chức vụ người ký văn bản'} name={'position'}>
              <Input maxLength={500} placeholder={'Nhập chức danh người ký'} />
            </Form.Item>
            <Form.Item label={'Hạn trả lời văn bản'} name={'due_date'}>
              <DatePicker format={DATE_FORMAT_LIST} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label={'Độ khẩn'} name={'priority'}>
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn độ khẩn --'}>
                <Option value={'0'}>Thường</Option>
                <Option value={'1'}>Khẩn</Option>
                <Option value={'2'}>Thượng khẩn</Option>
                <Option value={'3'}>Hỏa tốc</Option>
                <Option value={'4'}>Hỏa tốc hẹn giờ</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={'Lĩnh vực ban hành'}
              name={'sphere_of_promulgation'}>
              <Input maxLength={100} placeholder={'Nhập lĩnh vực ban hành'} />
            </Form.Item>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={24}>
                <Form.Item
                  label={
                    'Ký hiệu đơn vị, tổ chức, chữ viết tắt tên người soạn thảo văn bản'
                  }
                  name={'typer_notation'}>
                  <Input maxLength={100} placeholder={'Nhập ký hiệu'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={'Số lượng ban hành'}
                  name={'promulgation_amount'}>
                  <InputNumber style={{ width: '100%' }} min={1} max={10000} />
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Tổng số trang của văn bản'}
                  name={'page_amount'}>
                  <InputNumber style={{ width: '100%' }} min={1} max={10000} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ban hành theo chỉ đạo'}
                  name={'direction'}
                  valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={'Phụ lục'} name={'appendixes'}>
              <Input maxLength={200} placeholder={'Nhập thông tin phụ lục'} />
            </Form.Item>

            <Form.Item
              label={'Loại chỉ đạo'}
              name={'steering_type'}
              rules={[
                { required: true, message: ' Vui lòng chọn loại chỉ đạo!' },
                { validator: validator.validateEmptyString },
              ]}>
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn loại chỉ đạo --'}>
                <Option value={'0'}>Không phải là chỉ đạo</Option>
                <Option value={'1'}>Chỉ đạo</Option>
                <Option value={'2'}>Báo cáo chỉ đạo</Option>
              </Select>
            </Form.Item>

            <Form.Item label={'Nơi nhận'} name={'to_places'}>
              <Input maxLength={1000} placeholder={'Nhập nơi nhận'} />
            </Form.Item>

            <Form.Item
              label={'Lý do ban hành'}
              name={'bussiness_doc_reason'}
              rules={[
                {
                  required: true,
                  message: ' Vui lòng nhập lí do ban hành văn bản!',
                },
                { validator: validator.validateEmptyString },
              ]}>
              <Input maxLength={200} placeholder={'Nhập lí do ban hành'} />
            </Form.Item>

            {/*<Form.Item*/}
            {/*  label={'Gửi kèm văn bản giấy theo đường truyền thống'}*/}
            {/*  name={'paper'}*/}
            {/*  valuePropName="checked"*/}
            {/*>*/}
            {/*  <Switch/>*/}
            {/*</Form.Item>*/}

            <Form.Item
              name={'file_upload'}
              label={'Tài liệu đính kèm'}
              rules={[
                { required: true, message: ' Vui lòng chọn file đính kèm!' },
              ]}>
              <Dragger
                accept={'.pdf'}
                valuePropName={'fileList'}
                fileList={fileList}
                multiple={true}
                beforeUpload={handleAddFileToUpload}
                onChange={handleChangeFile}>
                <DraggerUploadStyled>
                  <CloudUploadOutlined />
                  <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
                </DraggerUploadStyled>
              </Dragger>
            </Form.Item>

            <FormActionFooter>
              <Button onClick={handleCancelCreate}>Huỷ bỏ</Button>
              <Button
                style={{ marginLeft: 10 }}
                type={'primary'}
                htmlType={'submit'}>
                Cập nhật văn bản
              </Button>
            </FormActionFooter>
          </Form>
        </Container>
      </ContentBlockWrapper>
    </ConnectedCommitteeDocumentLayout>
  )
}

ConnectedCommitteeDocumentOutgoingDocUpdatePage.propTypes = {}

export default inject(
  'commonStore',
  'connectedCommitteeDocumentStore',
  'fileStore',
  'authenticationStore',
  'loadingAnimationStore',
)(observer(ConnectedCommitteeDocumentOutgoingDocUpdatePage))
