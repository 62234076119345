import React, { useEffect, useState } from 'react'
import { Input, Row, Col, DatePicker, Select, message, Space } from 'antd'
import EmptyContent from '../../../components/EmptyContent'
import { inject, observer } from 'mobx-react'
import { DATE_FORMAT_LIST, ISO_DATE_FORMAT } from '../../../constants'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import utils from '../../../utils'

const { RangePicker } = DatePicker
const { Option } = Select

const AdvanceFilterBlock = props => {

  const {
    bookStore,
    loadingAnimationStore,
    authorityStore,
    internalDocumentStore,
  } = props

  const { queryParamsOutgoing } = internalDocumentStore
  const { bookGroupList, bookListOfGroup } = bookStore
  const { authorityIssuedsList } = authorityStore

  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)

  const [loadingBook, setLoadingBook] = useState(false)
  const [valueAuthorityIssued, setValueAuthorityIssued] = useState([])
  useEffect(() => {
    if(queryStringParse.authority_name  && queryStringParse.authority_name.trim() !== '') {
      setValueAuthorityIssued([queryStringParse.authority_name])
    }
  }, [])

  const onSearchTitle = title => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      title: title && title.trim(),
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      title: title ? title.trim() : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
  }

  const onChangeDateIssued = (value) => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      from_date_issued: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date_issued: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      from_date_issued: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date_issued: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
  }
  const onChangeDocumentType = (document_type) => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      document_type: document_type,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      document_type: document_type,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
  }
  const onChangeUrgencyLevel = urgency_level => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      urgency_level: urgency_level,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      urgency_level: urgency_level,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
  }

  const onSearchAuthorityIssued = (value) => {
    setValueAuthorityIssued(value.length > 0 ? [value[value.length - 1]] : [])
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      authority_name: value.length > 0  ? value[value.length - 1] : null,
    })

    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      authority_name: value.length > 0  ? value[value.length - 1] : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
  }

  const onSearchDocumentNumber = document_number => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      document_number: document_number && document_number.trim(),
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      document_number: document_number ? document_number.trim() : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
  }

  const onSearchSigner = signer => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      signer: signer ? signer.trim() : null,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      signer: signer ? signer.trim() : null,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
  }

  useEffect(() => {
    if (!queryParamsOutgoing.book_group_id) return
    (async () => {
      try {
        await bookStore.getBookByBookGroupId(queryParamsOutgoing.book_group_id)
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }, [])

  const onChangeBookGroupId = async book_group_id => {
    if (!book_group_id) {
      bookStore.clearBookListOfGroup()
      internalDocumentStore.setQueryParamsOutgoing({
        ...queryParamsOutgoing,
        page: 0,
        book_group_id: null,
        book_id: null,
      })
      const queryStringStringify = queryString.stringify({
        ...queryStringParse,
        page: 0,
        size: 10,
        book_group_id: null,
        book_id: null,
      }, {
        skipNull: true,
      })
      history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
      return
    }
    setLoadingBook(true)
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      book_id: null,
      book_group_id: book_group_id,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      book_id: null,
      book_group_id: book_group_id,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
    try {
      await bookStore.getBookByBookGroupId(book_group_id)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingBook(false)
    }
  }

  const onChangeBookId = book_id => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      book_id: book_id,
    })
    const queryStringStringify = queryString.stringify({
      ...queryStringParse,
      page: 0,
      size: 10,
      book_id: book_id,
    }, {
      skipNull: true,
    })
    history.replace(`/internal-document/di-noi-bo?${queryStringStringify}`)
  }

  const filterSelectOption = (input, option) => {
    return (
      utils.nonAccentVietnamese(option.name).indexOf(utils.nonAccentVietnamese(input)) >= 0
    );
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} sm={24} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Trích yếu / Số hiệu</span>
            <Input.Search
              onSearch={value => onSearchTitle(value)}
              placeholder={'Nhập từ khóa tìm kiếm'} allowClear
              defaultValue={queryStringParse.title}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Nhóm sổ văn bản</span>
            <Select
              style={{ width: '100%' }}
              loading={loadingAnimationStore.tableLoading}
              disabled={true}
              onChange={onChangeBookGroupId}
              defaultValue={queryParamsOutgoing.book_group_id && parseInt(queryParamsOutgoing.book_group_id)}
              showSearch notFoundContent={<EmptyContent />}
              optionFilterProp={'name'}
              placeholder={'-- Chọn nhóm sổ văn bản --'}>
              {
                bookGroupList.map(book =>
                  <Option key={book.id} value={book.id} name={book.name}>
                    {book.name}
                  </Option>,
                )}
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Sổ văn bản</span>
            <Select
              style={{ width: '100%' }}
              loading={loadingBook}
              allowClear
              value={queryParamsOutgoing.book_id && parseInt(queryParamsOutgoing.book_id)}
              onChange={onChangeBookId}
              showSearch notFoundContent={<EmptyContent />}
              filterOption={true} optionFilterProp={'name'}
              disabled={!queryParamsOutgoing.book_group_id}
              placeholder={'-- Chọn sổ văn bản --'}>
              {bookListOfGroup.map(book =>
                <Option key={book.id} value={book.id} name={book.name}>
                  {book.name}
                </Option>,
              )}
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Ngày ban hành</span>
            <RangePicker
              onChange={onChangeDateIssued}
              defaultValue={
                (queryStringParse.from_date_issued && queryStringParse.to_date_issued) ?
                  [moment(queryStringParse.from_date_issued), moment(queryStringParse.to_date_issued)] : null
              }
              placeholder={['Từ ngày', 'Đến ngày']}
              format={DATE_FORMAT_LIST} style={{ width: '100%' }}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Loại văn bản</span>
            <Select
              style={{ width: '100%' }}
              allowClear
              onChange={onChangeDocumentType}
              defaultValue={queryStringParse.document_type}
              notFoundContent={<EmptyContent />}
              placeholder={'-- Chọn loại văn bản --'}>
              <Option value={'020'}>Báo cáo</Option>
              <Option value={'021'}>Công văn</Option>
              <Option value={'022'}>Quyết định</Option>
              <Option value={'023'}>Thông báo - Giấy mời</Option>
              <Option value={'024'}>Tờ trình</Option>
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Mức độ xử lý</span>
            <Select
              style={{ width: '100%' }}
              allowClear
              onChange={onChangeUrgencyLevel}
              defaultValue={queryStringParse.urgency_level}
              notFoundContent={<EmptyContent />}
              placeholder={'-- Chọn độ khẩn --'}>
              <Option value={'001'}>Thường</Option>
              <Option value={'002'}>Quan trọng</Option>
              <Option value={'003'}>Rất quan trọng</Option>
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Cơ quan ban hành</span>
            <Select
              style={{width: '100%'}}
              mode='tags'
              value={valueAuthorityIssued}
              onInputKeyDown={() => setValueAuthorityIssued([])}
              onChange={onSearchAuthorityIssued}
              filterOption={filterSelectOption}
              allowClear showSearch showArrow
              optionFilterProp={'name'}
              notFoundContent={<EmptyContent />}
              placeholder={'-- Chọn hoặc nhập cơ quan ban hành --'}>
              {authorityIssuedsList.map(authorityIssued =>
                <Option
                  name={authorityIssued.name}
                  value={authorityIssued.name}
                  key={authorityIssued.id}
                >
                  {authorityIssued.name}
                </Option>,
              )}
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>
            <span>Người ký</span>
            <Input.Search
              placeholder={'Người ký'}
              onSearch={value => onSearchSigner(value)}
              defaultValue={queryStringParse.signer}
              allowClear
            />
          </Space>
        </Col>
      </Row>
    </div>
  )
}

AdvanceFilterBlock.propTypes = {}

export default inject(
  'bookStore', 'loadingAnimationStore', 'authorityStore', 'internalDocumentStore',
)(observer(AdvanceFilterBlock))
