import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, message, Progress, Slider } from 'antd'
import { EmptyText, WordBreak } from '../../components/Common/CellText'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import RichEditor from '../../components/RichEditor/RichEditor'
import { toJS } from 'mobx'
import moment from 'moment'
import { ACL_ACTION_TYPE, DATE_FORMAT_SLASH, MM_YYYY } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction'
import ListHistoryLogModal from '../ListHistoryLog/ListHistoryLogModal'

const MissionDetailUpdate = props => {
  const {
    resultTask,
    contentTask,
    taskStore,
    files,
    missionId,
    isDetailList,
    handleSetTaskDetail,
    handleSetIsRefreshListTask,
  } = props

  const percentComplete = resultTask.phan_tram_hoan_thanh
    ? parseInt(resultTask.phan_tram_hoan_thanh)
    : 0

  const [percentCompleteUpdate, setPercentCompleteUpdate] = useState(0)
  const [editorContentResult, setEditorContentResult] = useState()
  const [editorKienNghi, setEditorKienNghi] = useState()

  const [isUpdate, setIsUpdate] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  //
  const [isVisibleHistory, setIsVisibleHistory] = useState(false)
  const [documentHistory, setDocumentHistory] = useState({})

  useEffect(() => {
    setPercentCompleteUpdate(
      resultTask.phan_tram_hoan_thanh
        ? parseInt(resultTask.phan_tram_hoan_thanh)
        : 0
    )
    setEditorContentResult(resultTask.noi_dung_ket_qua_giao)
    setEditorKienNghi(resultTask.kien_nghi)
  }, [resultTask, isUpdate])
  const CONTENT_RESULT_REF = useRef()
  const KIEN_NGHI_REF = useRef()

  const handleUpdateMission = async () => {
    try {
      const submitData = {
        kien_nghi: KIEN_NGHI_REF.current.editor.getData(),
        noi_dung_ket_qua: CONTENT_RESULT_REF.current.editor.getData(),
        ty_le_hoan_thanh: percentCompleteUpdate,
        files: files.map(file => file.file_id),
      }
      setIsUpdating(true)
      await taskStore.updateTask(missionId, submitData)
      if (isDetailList) {
        const percentCompleteOrigin = resultTask.phan_tram_hoan_thanh
          ? parseInt(resultTask.phan_tram_hoan_thanh)
          : 0
        let processing = 'NOT_CHANGE'
        let completed = 'NOT_CHANGE'
        let chua_thuc_hien = 'NOT_CHANGE'

        if (
          percentCompleteOrigin === 0 &&
          percentCompleteUpdate !== 0 &&
          percentCompleteUpdate !== 100
        ) {
          chua_thuc_hien = 'DOWN'
          processing = 'UP'
          completed = 'NOT_CHANGE'
        }
        if (percentCompleteOrigin === 0 && percentCompleteUpdate === 100) {
          chua_thuc_hien = 'DOWN'
          processing = 'NOT_CHANGE'
          completed = 'UP'
        }
        if (
          percentCompleteOrigin === 100 &&
          percentCompleteUpdate !== 0 &&
          percentCompleteUpdate !== 100
        ) {
          completed = 'DOWN'
          processing = 'UP'
          chua_thuc_hien = 'NOT_CHANGE'
        }
        if (percentCompleteOrigin === 100 && percentCompleteUpdate === 0) {
          completed = 'DOWN'
          processing = 'NOT_CHANGE'
          chua_thuc_hien = 'UP'
        }

        if (
          percentCompleteOrigin !== 0 &&
          percentCompleteOrigin !== 100 &&
          percentCompleteUpdate === 0
        ) {
          processing = 'DOWN'
          chua_thuc_hien = 'UP'
          completed = 'NOT_CHANGE'
        }

        if (
          percentCompleteOrigin !== 0 &&
          percentCompleteOrigin !== 100 &&
          percentCompleteUpdate === 100
        ) {
          processing = 'DOWN'
          completed = 'UP'
          chua_thuc_hien = 'NOT_CHANGE'
        }
        if (
          percentCompleteOrigin !== 0 &&
          percentCompleteOrigin !== 100 &&
          percentCompleteUpdate !== 100 &&
          percentCompleteUpdate !== 0
        ) {
          processing = 'NOT_CHANGE'
          completed = 'NOT_CHANGE'
          chua_thuc_hien = 'NOT_CHANGE'
        }
        await taskStore.getTaskDetail(missionId)
        handleSetTaskDetail(toJS(taskStore.taskDetail))
        if (handleSetIsRefreshListTask) {
          handleSetIsRefreshListTask()
        }
        taskStore.setUpdateTaskItem({
          isRowUser: false,
          status: true,
          phan_tram_hoan_thanh: percentCompleteUpdate,
          task_code: missionId,
          processing: processing,
          completed: completed,
          chua_thuc_hien: chua_thuc_hien,
        })
      } else {
        await taskStore.getTaskDetail(missionId)
      }
      setIsUpdate(false)
      message.success('Cập nhật nhiệm vụ thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      setIsUpdating(false)
    }
  }

  const handleCloseHistory = async type => {
    setIsVisibleHistory(false)
  }

  return (
    <>
      <dt>Phần trăm thực hiện</dt>
      <dd>
        {!isUpdate ? (
          <Progress
            percent={percentComplete}
            trailColor={percentComplete === 0 ? '#ff4d4f' : null}
            status={percentComplete === 100 ? null : 'active'}
            style={{ width: '100%' }}
          />
        ) : (
          <Slider
            style={{ marginTop: 4 }}
            min={0}
            max={100}
            onChange={value => {
              setPercentCompleteUpdate(value)
            }}
            value={percentCompleteUpdate}
            trackStyle={{
              backgroundColor:
                percentCompleteUpdate === 100 ? '#52C41A' : '#1890ff',
            }}
            marks={{
              0: '0%',
              100: '100%',
            }}
          />
        )}
      </dd>
      <dt>Nội dung kết quả</dt>
      {!isUpdate ? (
        <dd>
          {resultTask.noi_dung_ket_qua_giao ? (
            WordBreak(resultTask.noi_dung_ket_qua_giao, true)
          ) : (
            <EmptyText>Không có nội dung kết quả</EmptyText>
          )}
        </dd>
      ) : (
        <div
          style={{
            width: '100%',
          }}>
          <RichEditor
            EDITOR_REF={CONTENT_RESULT_REF}
            placeholder={'Nhập nội dung kết quả...'}
            editorContent={editorContentResult}
          />
        </div>
      )}

      <dt>Kiến nghị</dt>
      {!isUpdate ? (
        <dd>
          {resultTask.kien_nghi ? (
            WordBreak(resultTask.kien_nghi, true)
          ) : (
            <EmptyText>Không có kiến nghị</EmptyText>
          )}
        </dd>
      ) : (
        <div style={{ width: '100%' }}>
          <RichEditor
            EDITOR_REF={KIEN_NGHI_REF}
            placeholder={'Nhập nội dung kết quả...'}
            editorContent={editorKienNghi}
          />
        </div>
      )}
      <dt>Hiển thị vào tháng</dt>
      <dd>
        {contentTask?.display_date ? (
          'Tháng ' + moment(contentTask?.display_date).format(MM_YYYY)
        ) : (
          <EmptyText>Không rõ</EmptyText>
        )}
      </dd>
      <dt>Ngày tạo</dt>
      <dd>
        {contentTask?.created_at ? (
          moment(contentTask?.created_at).format(DATE_FORMAT_SLASH)
        ) : (
          <EmptyText>Không rõ</EmptyText>
        )}
      </dd>
      <dt>Loại nhiệm vụ</dt>
      <dd>
        {contentTask && contentTask.tags && contentTask.tags.length !== 0 ? (
          contentTask.tags.map(tag => tag.name).join(', ')
        ) : (
          <EmptyText>Chưa phân loại</EmptyText>
        )}
      </dd>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Button
          onClick={() => {
            setDocumentHistory({
              code: missionId,
            })
            setIsVisibleHistory(!isVisibleHistory)
          }}>
          Lịch sử
        </Button>

        <div
          style={{
            margin: '20px 0',
            display: 'flex',
            gap: 8,
            justifyContent: 'flex-end',
          }}>
          {isUpdate ? (
            <>
              <Button
                icon={<CloseOutlined />}
                danger
                onClick={() => setIsUpdate(false)}>
                Hủy
              </Button>
              <Button
                type={'primary'}
                loading={isUpdating}
                onClick={handleUpdateMission}
                icon={<CheckOutlined />}>
                Lưu lại
              </Button>
            </>
          ) : (
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.cap_nhat__MISSION}>
              <Button
                onClick={() => setIsUpdate(true)}
                type={'primary'}
                icon={<EditOutlined />}>
                Cập nhật nhiệm vụ
              </Button>
            </AccessControlAction>
          )}
        </div>

        <ListHistoryLogModal
          type={'TASK'}
          isVisible={isVisibleHistory}
          taskInfo={documentHistory}
          handleCloseHistory={handleCloseHistory}
        />
      </div>
    </>
  )
}

MissionDetailUpdate.propTypes = {}

export default inject(
  'taskStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore',
  'selectPeopleStore',
)(observer(MissionDetailUpdate))
