import React, { memo, useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import PhieuTrinhLayout from '../PhieuTrinhLayout'
import { Button, Pagination, Tag } from 'antd'
import { TableBottomPaginationBlock } from '../../../../components/Common/Table'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import { PlusCircleOutlined } from '@ant-design/icons'
import {
  Text,
  trimOverlengthString,
} from '../../../../components/Common/CellText'
import uuid from 'uuid'
import PageTitle from '../../../../components/PageTitle'
import SubmissionForm from '../../../../components/SubmissionForm'
import TableComponent from '../../../../components/Common/TableComponent'

const XeCoPage = props => {
  const {
    authenticationStore,
    loadingAnimationStore,
    phieuTrinhFakeDataStore,
    accountStore,
    history,
  } = props

  const { isLeader, isClerical } = authenticationStore
  const { filterTable, listRecord, totalCount } = phieuTrinhFakeDataStore
  const { accountList } = accountStore

  const LOAI_PHIEU_TRINH = 'phiếu trình xe cộ'

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const STT_WIDTH = 60
  const NOI_DUNG_WIDTH = 650
  const TRANG_THAI_WIDTH = 150

  const tableColumn = [
    {
      title: 'STT',
      width: STT_WIDTH,
      render: record => record?.stt,
    },
    {
      title: 'Nội dung',
      width: NOI_DUNG_WIDTH,
      render: record => (
        <Text>
          {record.title && trimOverlengthString(record.title, NOI_DUNG_WIDTH)}
        </Text>
      ),
    },
    {
      title: 'Trạng thái',
      width: TRANG_THAI_WIDTH,
      render: record => (
        <Tag color={['orange', 'green', 'red', 'blue'][record?.status]}>
          {
            ['Chờ phê duyệt', 'Đã duyệt phiếu', 'Huỷ phiếu', 'Cần phê duyệt'][
              record?.status
            ]
          }
        </Tag>
      ),
    },
  ]

  const onChangeTable = useCallback(
    (current, size) => {
      console.log(accountList[0])
      if (size !== filterTable.size) {
        filterTable.page = 1
        filterTable.size = size
      } else if (current !== filterTable.page) {
        filterTable.page = current
      }
      phieuTrinhFakeDataStore.setFilter(filterTable)
    },
    [accountList, filterTable, phieuTrinhFakeDataStore]
  )

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    phieuTrinhFakeDataStore
      .getListLeave(filterTable, LOAI_PHIEU_TRINH)
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => phieuTrinhFakeDataStore.clearLeaveData()
  }, [filterTable, loadingAnimationStore, phieuTrinhFakeDataStore])

  const handleOk = () => {
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  return (
    <PhieuTrinhLayout
      title={
        <PageTitle location={props.location} title="Xe cộ">
          {(isLeader || isClerical) && (
            <Button onClick={showModal} type={'primary'}>
              <PlusCircleOutlined /> Tạo mới
            </Button>
          )}
        </PageTitle>
      }
      selectedKey="xe-co">
      <SubmissionForm
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />

      <Helmet>
        <title>Xe cộ | Hành Chính</title>
      </Helmet>

      <ContentBlockWrapper>
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(`/administrative/phieu-trinh/xe-co/${record.id}`),
            }
          }}
          rowKey={() => uuid()}
          columns={tableColumn}
          dataSource={listRecord}
          pagination={false}
          onChange={onChangeTable}
        />
        {totalCount > 0 ? (
          <TableBottomPaginationBlock>
            <Pagination
              current={filterTable.page}
              pageSize={filterTable.size}
              total={totalCount}
              onChange={onChangeTable}
              showSizeChanger={false}
              showLessItems
            />
          </TableBottomPaginationBlock>
        ) : null}
      </ContentBlockWrapper>
    </PhieuTrinhLayout>
  )
}

XeCoPage.propTypes = {}

export default memo(
  inject(
    'authenticationStore',
    'loadingAnimationStore',
    'accountStore',
    'phieuTrinhFakeDataStore'
  )(observer(XeCoPage))
)
