import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  MM_YYYY,
  TASK_DOCUMENT_TYPE,
} from '../../constants'
import {
  ButtonsWrapper,
  DocumentList,
  FormActionFooter,
  OriginFilesList,
  SelectList,
  SelectTitle,
} from './MissionEditPageStyled'
import { blue, green, yellowPrimary } from '../../color'
import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { AvatarHasName } from '../../components/Common/Avatar'
import EmptyContent from '../../components/EmptyContent'
import RichEditor from '../../components/RichEditor/RichEditor'
import { EmptyText } from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import utils from '../../utils'
import { toJS } from 'mobx'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import ModalIncomingDocumentList from '../MissionCreatePage/ModalIncomingDocumentList'
import ModalOutgoingDocumentList from '../MissionCreatePage/ModalOutgoingDocumentList'
import SelectPeoplePopup from '../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../components/SelectGroupPopup/SelectGroupPopup'
import queryString from 'query-string'
import TaskTagPicker from '../../components/Shared/TaskTagPicker'

const { Option, OptGroup } = Select

const MissionEditForm = props => {
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const queryStringStringify = queryString.stringify(queryStringParse, {
    skipNull: true,
  })
  const {
    loadingAnimationStore,
    taskStore,
    fileStore,
    internalDocumentStore,
    selectPeopleStore,
    taskCode,
    handleCancelUpdate,
    handleAfterUpdate,
  } = props
  const [form] = Form.useForm()
  const CONTENT_REF = useRef()
  const NOTE_REF = useRef()

  const missionId = taskCode
  const { taskDetail } = taskStore
  const contentTask = taskDetail?.contentTask

  const [editorContent, setEditorContent] = useState(null)
  const [editorNote, setEditorNote] = useState(null)

  const {
    assigneePopupType,
    setSelectUserData,
    selectUserData,
    setSelectDepartmentData,
    selectDepartmentData,
    setSelectGroupData,
    selectGroupData,
    originDepartmentWithUserList,
  } = selectPeopleStore

  const {
    taskLevelList,
    unitList,
    selectedIncomingDocument,
    selectedOutgoingDocument,
  } = taskStore

  const [fileList, setFileList] = useState([])
  // Incoming document
  const [
    isVisibleModalIncomingDocument,
    setIsVisibleModalIncomingDocument,
  ] = useState(false)
  // Ougoing document
  const [
    isVisibleModalOutgoingDocument,
    setIsVisibleModalOutgoingDocument,
  ] = useState(false)

  useEffect(() => {
    if (!missionId) return
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        const res = await taskStore.getTaskDetail(missionId)
        setEditorContent(res.data.content[0]?.noi_dung)
        setEditorNote(res.data.content[0]?.ghi_chu)
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      taskStore.clearTaskDetail()
    }
  }, [missionId])

  const [leaderList, setLeaderList] = useState([])
  const [handlerList, setHandlerList] = useState([])
  const [combinerList, setCombinerList] = useState([])

  const [originFiles, setOriginFiles] = useState([])

  const assigneesList = contentTask?.assignees
    ? toJS(contentTask)?.assignees.filter(
        el => el.permission !== ASSIGNEE_TYPE.CREATE
      )
    : []
  const userList = assigneesList.filter(
    el => el.assignee_type === ASSIGNEE_TYPE.USER
  )
  const departmentList = assigneesList.filter(
    el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT
  )
  const groupList = assigneesList.filter(
    el => el.assignee_type === ASSIGNEE_TYPE.GROUP
  )
  const documentList = contentTask?.van_bans || []
  const originIncomingDocumentList = documentList
    .filter(document => document.loai_van_ban === TASK_DOCUMENT_TYPE.INCOMING)
    .map(document => ({
      code: document.code_van_ban,
      title: document.ten_van_ban,
      document_number: document.document_number,
    }))
  const originOutgoingDocumentList = documentList
    .filter(document => document.loai_van_ban === TASK_DOCUMENT_TYPE.OUTGOING)
    .map(document => ({
      code: document.code_van_ban,
      title: document.ten_van_ban,
      document_number: document.document_number,
    }))

  useEffect(() => {
    setLeaderList([
      ...toJS(selectUserData)[ASSIGNEE_TYPE.LEADER],
      ...toJS(selectDepartmentData)[ASSIGNEE_TYPE.LEADER],
      ...toJS(selectGroupData)[ASSIGNEE_TYPE.LEADER],
    ])
    setHandlerList([
      ...toJS(selectUserData)[ASSIGNEE_TYPE.HANDLER],
      ...toJS(selectDepartmentData)[ASSIGNEE_TYPE.HANDLER],
      ...toJS(selectGroupData)[ASSIGNEE_TYPE.HANDLER],
    ])
    setCombinerList([
      ...toJS(selectUserData)[ASSIGNEE_TYPE.COMBINER],
      ...toJS(selectDepartmentData)[ASSIGNEE_TYPE.COMBINER],
      ...toJS(selectGroupData)[ASSIGNEE_TYPE.COMBINER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    if (!taskDetail) return
    setOriginFiles(contentTask?.ket_qua?.files || [])

    if (
      selectUserData[ASSIGNEE_TYPE.LEADER].length === 0 &&
      selectUserData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectUserData[ASSIGNEE_TYPE.COMBINER].length === 0
    ) {
      setSelectUserData(selectPeopleStore.convertSelectDataObj(userList))
    }

    if (
      selectDepartmentData[ASSIGNEE_TYPE.LEADER].length === 0 &&
      selectDepartmentData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectDepartmentData[ASSIGNEE_TYPE.COMBINER].length === 0
    ) {
      setSelectDepartmentData(
        selectPeopleStore.convertSelectDataObj(departmentList)
      )
    }

    if (
      selectGroupData[ASSIGNEE_TYPE.LEADER].length === 0 &&
      selectGroupData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectGroupData[ASSIGNEE_TYPE.COMBINER].length === 0
    ) {
      setSelectGroupData(selectPeopleStore.convertSelectDataObj(groupList))
    }

    taskStore.setSelectedIncomingDocument(originIncomingDocumentList)
    taskStore.setSelectedOutgoingDocument(originOutgoingDocumentList)
    form.setFieldsValue({
      id_cap_giao: contentTask?.cap_giao?.id_cap_giao,
      ngay_bat_dau:
        contentTask?.ngay_bat_dau && moment(contentTask?.ngay_bat_dau),
      ngay_ket_thuc:
        contentTask?.ngay_ket_thuc && moment(contentTask?.ngay_ket_thuc),
      display_date:
        contentTask?.display_date && moment(contentTask?.display_date),
      content: contentTask?.noi_dung,
      ghi_chu: contentTask?.ghi_chu,
      id_user_don_vi:
        contentTask?.chu_tri?.id_chu_tri &&
        contentTask?.chu_tri?.id_chu_tri + '',
      // loai_nhiem_vu: contentTask?.loai_nhiem_vu,
      tag_codes: contentTask?.tags.map(tag => tag.code)[0],
    })
  }, [taskDetail, originDepartmentWithUserList])

  const onFinish = async value => {
    if (leaderList.length === 0) {
      return message.info('Bạn chưa chọn lãnh đạo giao việc.')
    }
    const {
      id_cap_giao,
      // loai_nhiem_vu,
      ngay_bat_dau,
      ngay_ket_thuc,
      display_date,
      tag_codes,
    } = value
    const listAssignOrigin = [...userList, ...departmentList, ...groupList].map(
      el => ({
        assignee_code: el.assignee_code,
        assignee_type: el.assignee_type,
        permission: el.permission,
      })
    )
    const listAssignAfter = [
      ...leaderList,
      ...combinerList,
      ...handlerList,
    ].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const new_items_assign = []
    const remove_items_assign = []
    listAssignAfter.forEach(assignAfter => {
      if (
        !listAssignOrigin.some(
          assignOrigin =>
            assignOrigin.assignee_code + assignOrigin.permission ===
            assignAfter.assignee_code + assignAfter.permission
        )
      ) {
        new_items_assign.push({
          assignee_code: assignAfter.assignee_code,
          assignee_type: assignAfter.assignee_type,
          permission: assignAfter.permission,
        })
      }
    })
    listAssignOrigin.forEach(assignOrigin => {
      if (
        !listAssignAfter.some(
          assignAfter =>
            assignAfter.assignee_code + assignAfter.permission ===
            assignOrigin.assignee_code + assignOrigin.permission
        )
      ) {
        remove_items_assign.push({
          assignee_code: assignOrigin.assignee_code,
          assignee_type: assignOrigin.assignee_type,
          permission: assignOrigin.permission,
        })
      }
    })
    const new_items_document = []
    const remove_items_document = []
    const convertDocument = (docList, typeDoc) =>
      docList.map(doc => ({
        code_van_ban: doc.code,
        type: typeDoc,
      }))

    const originDocumentList = [
      ...convertDocument(
        originIncomingDocumentList,
        TASK_DOCUMENT_TYPE.INCOMING
      ),
      ...convertDocument(
        originOutgoingDocumentList,
        TASK_DOCUMENT_TYPE.OUTGOING
      ),
    ]
    const afterDocumentList = [
      ...convertDocument(selectedIncomingDocument, TASK_DOCUMENT_TYPE.INCOMING),
      ...convertDocument(selectedOutgoingDocument, TASK_DOCUMENT_TYPE.OUTGOING),
    ]
    afterDocumentList.forEach(afterDocument => {
      if (
        !originDocumentList.some(
          originDocument =>
            originDocument.code_van_ban === afterDocument.code_van_ban
        )
      ) {
        new_items_document.push({
          code_van_ban: afterDocument.code_van_ban,
          type: afterDocument.type,
        })
      }
    })
    originDocumentList.forEach(originDocument => {
      if (
        !afterDocumentList.some(
          afterDocument =>
            afterDocument.code_van_ban === originDocument.code_van_ban
        )
      ) {
        remove_items_document.push({
          code_van_ban: originDocument.code_van_ban,
          type: originDocument.type,
        })
      }
    })
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    try {
      loadingAnimationStore.showSpinner(true)
      const new_items_file = []
      const remove_items_file = []
      const response = await Promise.all(batchUploadArr)
      const originFileList = (contentTask?.ket_qua?.files || []).map(
        file => file.file_id
      )
      const afterFileList = [
        ...response.map(el => el.data.file_id),
        ...originFiles.map(file => file.file_id),
      ]
      afterFileList.forEach(file => {
        if (!originFileList.includes(file)) {
          new_items_file.push(file)
        }
      })
      originFileList.forEach(file => {
        if (!afterFileList.includes(file)) {
          remove_items_file.push(file)
        }
      })
      const submitData = {
        ghi_chu: NOTE_REF.current.editor.getData(),
        id_cap_giao: id_cap_giao,
        id_don_vi: '',
        // loai_nhiem_vu: loai_nhiem_vu,
        ngay_bat_dau: ngay_bat_dau ? moment(ngay_bat_dau).toISOString() : null,
        ngay_ket_thuc: ngay_ket_thuc
          ? moment(ngay_ket_thuc).toISOString()
          : null,
        display_date: display_date ? moment(display_date).toISOString() : null,
        noi_dung: CONTENT_REF.current.editor.getData(),
        gui_thong_bao: '001',
        van_ban_lq_update: {
          remove_items: remove_items_document,
          new_items: new_items_document,
        },
        assign_person_update: {
          remove_items: remove_items_assign,
          new_items: new_items_assign,
        },
        id_file_dinh_kem_update: {
          remove_items: remove_items_file,
          new_items: new_items_file,
        },
        tag_codes: [tag_codes],
      }

      await taskStore.editTask(missionId, submitData)
      handleAfterUpdate('REFRESH')
      message.success(`Chỉnh sửa nhiệm vụ thành công`)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }, [])
  const handleOpenModalIncomingDocumentList = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await internalDocumentStore.getInternalDocumentIncomingSystemList()
      setIsVisibleModalIncomingDocument(true)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const handleOpenModalOutgoingDocumentList = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await internalDocumentStore.getInternalDocumentOutgoingSystemList()
      setIsVisibleModalOutgoingDocument(true)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const [isVisibleSelectPeoplePopup, setIsVisibleSelectPeoplePopup] = useState(
    false
  )
  const [isVisibleSelectGroupPopup, setIsVisibleSelectGroupPopup] = useState(
    false
  )

  const handleOpenSelectPeoplePopup = type => {
    setIsVisibleSelectPeoplePopup(true)
    selectPeopleStore.setAssigneePopupType(type)
  }
  const handleCancelSelectUser = useCallback(() => {
    setIsVisibleSelectPeoplePopup(false)
    setSelectUserData({
      ...selectUserData,
      [assigneePopupType]: [],
    })
    setSelectDepartmentData({
      ...selectDepartmentData,
      [assigneePopupType]: [],
    })
    selectPeopleStore.setAssigneePopupType(null)
  }, [selectUserData, selectDepartmentData, assigneePopupType])
  const handleCancelSelectGroup = useCallback(() => {
    setIsVisibleSelectGroupPopup(false)
    setSelectGroupData({
      ...selectGroupData,
      [assigneePopupType]: [],
    })
    selectPeopleStore.setAssigneePopupType(null)
  }, [selectGroupData, assigneePopupType])
  const handleOpenSelectGroupPopup = type => {
    setIsVisibleSelectGroupPopup(true)
    selectPeopleStore.setAssigneePopupType(type)
  }
  const handleRemoveSelect = useCallback(
    item => {
      if (item.assignee_type === ASSIGNEE_TYPE.USER) {
        setSelectUserData({
          ...selectUserData,
          [item.permission]: selectUserData[item.permission].filter(
            el => el.id !== item.id
          ),
        })
      }
      if (item.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) {
        setSelectDepartmentData({
          ...selectDepartmentData,
          [item.permission]: selectDepartmentData[item.permission].filter(
            el => el.id !== item.id
          ),
        })
      }
      if (item.assignee_type === ASSIGNEE_TYPE.GROUP) {
        setSelectGroupData({
          ...selectGroupData,
          [item.permission]: selectGroupData[item.permission].filter(
            el => el.id !== item.id
          ),
        })
      }
    },
    [selectUserData, selectDepartmentData, selectGroupData]
  )

  return (
    <>
      <Form
        scrollToFirstError={true}
        name={'create-task'}
        layout={'vertical'}
        onFinish={onFinish}
        form={form}>
        <Row type={'flex'} gutter={30}>
          <Col md={24} lg={8}>
            <Form.Item
              label={'Ngày bắt đầu:'}
              name={'ngay_bat_dau'}
              rules={[
                { required: true, message: 'Vui lòng chọn ngày bắt đầu!' },
              ]}>
              <DatePicker
                placeholder={'Chọn ngày bắt đầu'}
                style={{ width: '100%' }}
                format={DATE_FORMAT_LIST}
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={8}>
            <Form.Item label={'Ngày kết thúc:'} name={'ngay_ket_thuc'}>
              <DatePicker
                placeholder={'Chọn ngày kết thúc'}
                style={{ width: '100%' }}
                format={DATE_FORMAT_LIST}
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={8}>
            <Form.Item
              label={'Hiển thị nhiệm vụ vào tháng:'}
              name={'display_date'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn tháng hiển thị nhiệm vụ!',
                },
              ]}>
              <DatePicker
                style={{ width: '100%' }}
                picker="month"
                placeholder={'Chọn tháng'}
                format={MM_YYYY}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type={'flex'} gutter={30}>
          <Col md={24} lg={8}>
            <SelectTitle>
              <span>
                <span
                  style={{
                    color: '#ff4d4f',
                    fontFamily: 'SimSun, sans-serif',
                    marginRight: '-4px',
                  }}>
                  *{' '}
                </span>
                <span style={{ marginRight: '10px' }}>Lãnh đạo giao việc:</span>
              </span>
              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectPeoplePopup(ASSIGNEE_TYPE.LEADER)
                    }
                  />
                </Tooltip>
                <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() =>
                      handleOpenSelectGroupPopup(ASSIGNEE_TYPE.LEADER)
                    }
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {leaderList.map(el => {
                let icon = null
                if (el.assignee_type === ASSIGNEE_TYPE.GROUP) {
                  icon = <TeamOutlined />
                }
                if (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) {
                  icon = <BankOutlined />
                }
                return (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => handleRemoveSelect(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={icon}
                    />
                  </Tag>
                )
              })}
            </SelectList>
          </Col>

          <Col md={24} lg={8}>
            <SelectTitle>
              <span style={{ marginRight: '10px' }}>Xử lý chính:</span>
              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectPeoplePopup(ASSIGNEE_TYPE.HANDLER)
                    }
                  />
                </Tooltip>
                <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() =>
                      handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)
                    }
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {handlerList.map(el => {
                let icon = null
                if (el.assignee_type === ASSIGNEE_TYPE.GROUP) {
                  icon = <TeamOutlined />
                }
                if (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) {
                  icon = <BankOutlined />
                }
                return (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => handleRemoveSelect(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={icon}
                    />
                  </Tag>
                )
              })}
            </SelectList>
          </Col>
          <Col md={24} lg={8}>
            <SelectTitle>
              <span style={{ marginRight: '10px' }}>Người phối hợp:</span>
              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectPeoplePopup(ASSIGNEE_TYPE.COMBINER)
                    }
                  />
                </Tooltip>
                <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() =>
                      handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)
                    }
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {combinerList.map(el => {
                let icon = null
                if (el.assignee_type === ASSIGNEE_TYPE.GROUP) {
                  icon = <TeamOutlined />
                }
                if (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) {
                  icon = <BankOutlined />
                }
                return (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => handleRemoveSelect(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={icon}
                    />
                  </Tag>
                )
              })}
            </SelectList>
          </Col>
        </Row>
        <div style={{ height: 10 }} />
        <Row type={'flex'} gutter={30}>
          <Col md={24} lg={8}>
            <Form.Item
              label={'Cấp giao nhiệm vụ:'}
              name={'id_cap_giao'}
              rules={[
                { required: true, message: 'Vui lòng cấp giao nhiệm vụ!' },
              ]}>
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn Cấp giao nhiệm vụ --'}>
                {taskLevelList?.map(taskLevel => (
                  <Option key={taskLevel.id_level} value={taskLevel.id_level}>
                    {taskLevel.ten_cap}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col md={24} lg={8}>
            <Form.Item label={'Loại nhiệm vụ:'} name={'loai_nhiem_vu'}>
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'-- Chọn loại nhiệm vụ --'}>
                {Object.values(TASK_TYPE).map(value => {
                  return <Option value={value.key}>{value.value}</Option>
                })}
              </Select>
            </Form.Item>
          </Col> */}

          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={'Loại nhiệm vụ:'}
              name="tag_codes"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn loại nhiệm vụ',
                  // type: 'array',
                },
              ]}>
              <TaskTagPicker value={contentTask?.tags.map(tag => tag.code)} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={'Nội dung:'} name={'content'}>
          <RichEditor
            EDITOR_REF={CONTENT_REF}
            placeholder={'Nhập nội dung...'}
            editorContent={editorContent}
          />
        </Form.Item>
        <Form.Item
          label={
            <>
              <span style={{ marginRight: 20 }}>Văn bản liên quan:</span>
              <ButtonsWrapper>
                <Button
                  size={'small'}
                  onClick={handleOpenModalIncomingDocumentList}
                  icon={<CopyOutlined style={{ color: green }} />}>
                  VB đến
                </Button>
                <Button
                  size={'small'}
                  onClick={handleOpenModalOutgoingDocumentList}
                  icon={<CopyOutlined style={{ color: blue }} />}>
                  VB đi
                </Button>
              </ButtonsWrapper>
            </>
          }
          name={'van_ban_lq'}>
          {!selectedIncomingDocument.length &&
          !selectedOutgoingDocument.length ? (
            <EmptyText>Bạn chưa chọn văn bản nào</EmptyText>
          ) : (
            <DocumentList>
              {selectedIncomingDocument.map(el => (
                <li key={el.code}>
                  <CopyOutlined style={{ color: green, marginRight: 8 }} />
                  {`${el.document_number} - ${el.title}`}
                </li>
              ))}
              {selectedOutgoingDocument.map(el => (
                <li key={el.code}>
                  <CopyOutlined style={{ color: blue, marginRight: 8 }} />
                  {`${el.document_number} - ${el.title}`}
                </li>
              ))}
            </DocumentList>
          )}
        </Form.Item>

        <Form.Item label={'Tài liệu đính kèm'} name={'id_file_dinh_kem'}>
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            onChange={handleChangeFile}
            beforeUpload={() => false}
            iconRender={() => <FileTextOutlined />}>
            <Button htmlType={'button'}>
              <UploadOutlined />
              Chọn tài liệu đính kèm
            </Button>
          </Upload>
        </Form.Item>

        <OriginFilesList>
          {originFiles.map(file => (
            <div className={'file'} key={file.file_id}>
              <FileItem
                file_id={file.file_id}
                file_name={file.file_title}
                file_type={utils.getExtensionFile(file.file_title)}
              />
              <span
                className={'delete-icon'}
                onClick={() =>
                  setOriginFiles(
                    originFiles.filter(el => el.file_id !== file.file_id)
                  )
                }>
                <DeleteOutlined />
              </span>
            </div>
          ))}
        </OriginFilesList>

        <Form.Item label={'Ghi chú:'} name={'ghi_chu'}>
          <RichEditor
            EDITOR_REF={NOTE_REF}
            placeholder={'Nhập ghi chú...'}
            editorContent={editorNote}
          />
        </Form.Item>

        <FormActionFooter>
          <Button
            icon={<CloseOutlined />}
            danger
            onClick={() => handleCancelUpdate()}>
            Huỷ bỏ
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            icon={<CheckOutlined />}
            type={'primary'}
            htmlType={'submit'}>
            Đồng ý chỉnh sửa
          </Button>
        </FormActionFooter>
      </Form>

      <ModalIncomingDocumentList
        handleCloseModal={() => setIsVisibleModalIncomingDocument(false)}
        onClearDocument={() => {
          setIsVisibleModalIncomingDocument(false)
          taskStore.setSelectedIncomingDocument([])
        }}
        onSubmitModal={() => setIsVisibleModalIncomingDocument(false)}
        isVisibleModalIncomingDocument={isVisibleModalIncomingDocument}
      />
      <ModalOutgoingDocumentList
        handleCloseModal={() => setIsVisibleModalOutgoingDocument(false)}
        onClearDocument={() => {
          setIsVisibleModalOutgoingDocument(false)
          taskStore.setSelectedOutgoingDocument([])
        }}
        onSubmitModal={() => setIsVisibleModalOutgoingDocument(false)}
        isVisibleModalOutgoingDocument={isVisibleModalOutgoingDocument}
      />

      <SelectPeoplePopup
        handleCancelSelectUser={handleCancelSelectUser}
        handleSubmitSelectUser={() => setIsVisibleSelectPeoplePopup(false)}
        isVisibleSelectPeoplePopup={isVisibleSelectPeoplePopup}
      />
      <SelectGroupPopup
        groupType={'nhiem_vu'}
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </>
  )
}

MissionEditForm.propTypes = {}

export default inject(
  'departmentStore',
  'authenticationStore',
  'loadingAnimationStore',
  'taskStore',
  'internalDocumentStore',
  'commonStore',
  'fileStore',
  'selectUserStore',
  'internalDocumentStore',
  'selectPeopleStore'
)(observer(MissionEditForm))
