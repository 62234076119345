import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Row,
  Space,
  Tag,
  Tooltip,
} from 'antd'
import { blue, yellowPrimary } from '../../../color'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  WORK_STATUS,
} from '../../../constants'
import { AvatarHasName } from '../../../components/Common/Avatar'
import { SelectList, SelectTitle, TitleModal } from './PopupAddPersonWorkStyled'
import { inject, observer } from 'mobx-react'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../components/SelectGroupPopup/SelectGroupPopup'
import utils from '../../../utils'
import moment from 'moment'

const PopupAddPersonWork = props => {
  const {
    isVisibleAddPersonWork,
    handleCloseAddPersonWork,
    selectPeopleStore,
    workStore,
    loadingAnimationStore,
  } = props

  const { workDetail, workList } = workStore
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    originDepartmentWithUserList,
  } = selectPeopleStore
  const { assignees, work_type } = workDetail

  const [form] = Form.useForm()

  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])

  const userList = assignees
    ? assignees.filter(
        el =>
          el.assignee_type === ASSIGNEE_TYPE.USER &&
          el.permission !== ASSIGNEE_TYPE.CREATE
      )
    : []

  const departmentList = assignees
    ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT)
    : []

  const groupList = assignees
    ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP)
    : []

  useEffect(() => {
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    if (!isVisibleAddPersonWork) return

    if (
      selectUserData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectUserData[ASSIGNEE_TYPE.FOLLOWER].length === 0
    ) {
      selectPeopleStore.setSelectUserData(
        selectPeopleStore.convertSelectDataObj(userList)
      )
    }

    if (
      selectDepartmentData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER].length === 0
    ) {
      selectPeopleStore.setSelectDepartmentData(
        selectPeopleStore.convertSelectDataObj(departmentList)
      )
    }

    if (
      selectGroupData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectGroupData[ASSIGNEE_TYPE.FOLLOWER].length === 0
    ) {
      selectPeopleStore.setSelectGroupData(
        selectPeopleStore.convertSelectDataObj(groupList)
      )
    }

    // console.log('workDetail.due_date', workDetail.due_date)
    if (workDetail.due_date) {
      form.setFieldsValue({
        due_date: moment(workDetail.due_date),
      })
    }
  }, [originDepartmentWithUserList])

  const handleCancel = () => {
    handleCloseAddPersonWork()
  }

  const isElectronicWork = work_type === WORK_STATUS.ELECTRONIC

  const handleSubmit = async () => {
    const code_work = workDetail?.code
    const new_items_assign = []
    const remove_items_assign = []
    const listAssignOrigin = [...userList, ...departmentList, ...groupList].map(
      el => ({
        assignee_code: el.assignee_code,
        assignee_type: el.assignee_type,
        permission: el.permission,
      })
    )
    const listAssignAfter = [...followerList, ...handlerList].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    if (handlerList.length === 0) {
      return isElectronicWork
        ? message.info('Bạn chưa chọn lãnh đạo ký duyệt!')
        : message.info('Bạn chưa chọn người xử lý!')
    }
    listAssignAfter.forEach(assignAfter => {
      if (
        !listAssignOrigin.some(
          assignOrigin =>
            assignOrigin.assignee_code + assignOrigin.permission ===
            assignAfter.assignee_code + assignAfter.permission
        )
      ) {
        new_items_assign.push({
          assignee_code: assignAfter.assignee_code,
          assignee_type: assignAfter.assignee_type,
          permission: assignAfter.permission,
        })
      }
    })
    listAssignOrigin.forEach(assignOrigin => {
      if (
        !listAssignAfter.some(
          assignAfter =>
            assignAfter.assignee_code + assignAfter.permission ===
            assignOrigin.assignee_code + assignOrigin.permission
        )
      ) {
        remove_items_assign.push({
          assignee_code: assignOrigin.assignee_code,
          assignee_type: assignOrigin.assignee_type,
          permission: assignOrigin.permission,
        })
      }
    })

    try {
      loadingAnimationStore.showSpinner(true)
      const dataSubmit = {
        new_items: new_items_assign,
        remove_items: remove_items_assign,
        due_date: form.getFieldValue('due_date'),
      }
      await workStore.updatePeopleInvole(code_work, dataSubmit)
      const responseDetail = await workStore.getWorkDetail(code_work)
      const parentId = utils.getParameterByName('parent_id')
      if (parentId) {
        await workStore.getListSubWork(responseDetail.data.code)
      } else {
        const newWorkList = [...workList]
        const indexParentTarget = workList.findIndex(
          item => item.code === code_work
        )
        newWorkList[indexParentTarget] = {
          ...responseDetail.data,
          has_subwork: responseDetail.data?.subwork?.length > 0,
        }
        workStore.setWorkList(newWorkList)
      }
      selectPeopleStore.clearSelected()
      handleCloseAddPersonWork()
      message.success('Thêm người thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <div>
      <Modal
        style={{ top: 40 }}
        closable={false}
        width={800}
        title={
          <TitleModal>
            <span className={'title-modal'}>Thêm người</span>
            <Space>
              <Button danger onClick={handleCancel}>
                Hủy bỏ
              </Button>
              <Button type={'primary'} onClick={handleSubmit}>
                Đồng ý
              </Button>
            </Space>
          </TitleModal>
        }
        visible={isVisibleAddPersonWork}
        footer={null}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <SelectTitle>
              <span>
                <span
                  style={{
                    color: '#ff4d4f',
                    fontFamily: 'SimSun, sans-serif',
                    marginRight: '-4px',
                  }}>
                  *{' '}
                </span>
                <span style={{ marginRight: '10px' }}>
                  {isElectronicWork ? 'Lãnh đạo ký duyệt:' : 'Người xử lý:'}
                </span>
              </span>
              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)
                    }
                  />
                </Tooltip>
                {!isElectronicWork && (
                  <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                    <Button
                      icon={<TeamOutlined />}
                      style={{ color: yellowPrimary }}
                      onClick={() =>
                        handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)
                      }
                    />
                  </Tooltip>
                )}
              </Space>
            </SelectTitle>
            <SelectList>
              {handlerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>

          <Col span={12}>
            <SelectTitle>
              <span>
                <span style={{ marginRight: '10px' }}>Người theo dõi:</span>
              </span>
              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectUserDepartmentPopup(
                        ASSIGNEE_TYPE.FOLLOWER
                      )
                    }
                  />
                </Tooltip>
                <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() =>
                      handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)
                    }
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {followerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>
        </Row>
        <Row>
          <Form form={form} layout="vertical">
            <Form.Item label={'Ngày kết thúc:'} name={'due_date'}>
              <DatePicker format={DATE_FORMAT_LIST} />
            </Form.Item>
          </Form>
        </Row>
        <SelectPeoplePopup
          onlyOneUser={
            isElectronicWork
              ? selectPeopleStore.assigneePopupType === ASSIGNEE_TYPE.HANDLER
              : false
          }
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />
        <SelectGroupPopup
          isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
          handleCancelSelectGroup={handleCancelSelectGroup}
          handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
        />
      </Modal>
    </div>
  )
}

PopupAddPersonWork.propTypes = {}

export default inject(
  'workStore',
  'selectPeopleStore',
  'loadingAnimationStore'
)(observer(PopupAddPersonWork))
