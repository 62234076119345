import { ASSIGNEE_TYPE, WORK_STATUS, WORK_TYPE } from './constants'

const common_history_log = {
  enumToText: enumValue => {
    let text = ''
    switch (enumValue) {
      case 'CREATE':
        text = 'Tạo'
        break
      case 'UPDATE':
        text = 'Chỉnh sửa'
        break
      case 'DELETE':
        text = 'Xóa'
        break
      case 'UPDATE_STATUS':
        text = 'Cập nhật trạng thái'
        break
      case 'MARK_IMPORTANT':
        text = 'Đánh dấu ưu tiên'
        break
      case 'ADD_SUB_WORK':
        text = 'Thêm công việc phụ'
        break
      case 'UPDATE_PROGRESS':
        text = 'Cập nhật tiến độ'
        break
      case 'MOVE':
        text = 'Chuyển sang tháng'
        break
      case 'ADD_WORK_FROM_TASK':
        text = 'Tạo công việc từ nhiệm vụ'
        break
      default:
        text = undefined
        break
    }
    return text
  },
}

const assign_type = {
  enumToText: enumValue => {
    let text = ''
    switch (enumValue) {
      case ASSIGNEE_TYPE.HANDLER:
        text = 'Xử lý chính'
        break
      case ASSIGNEE_TYPE.LEADER:
        text = 'Lãnh đạo giao việc'
        break
      case ASSIGNEE_TYPE.COMBINER:
        text = 'Phối hợp xử lý'
        break
      case ASSIGNEE_TYPE.FOLLOWER:
        text = 'Theo dõi'
        break
    }
    return text
  },
}

const field_history_log = {
  toText: (/** @type {any} */ fieldLabel, /** @type {any} */ type) => {
    switch (type) {
      case 'TASK':
        switch (fieldLabel) {
          case 'noiDung':
            return 'Nội dung'
          case 'noiDungKetQua':
            return 'Nội dung kết quả'
          case 'kienNghi':
            return 'Kiến nghị'
          case 'phanTramHoanThanh':
            return 'Phần trăm thực hiện'
          case 'ghiChu':
            return 'Ghi chú'
          case 'idTaskLevel':
            return 'Cấp giao nhiệm vụ'
          case 'ngayBatDau':
            return 'Ngày bắt đầu'
          case 'ngayKetThuc':
            return 'Ngày kết thúc'
          case 'createdAt':
            return 'Ngày tạo'
          case 'updatedAt':
            return 'Ngày cập nhật'
          case 'loaiNhiemVu':
            return 'Loại nhiệm vụ'
          case 'displayDate':
            return 'Hiển thị vào tháng'
          case 'assignees':
            return 'Vai trò'
          case 'documents':
            return 'Văn bản liên quan'
          case 'files':
            return 'Tài liệu đính kèm'
          case 'works':
            return 'Công việc'
          default:
            return fieldLabel
        }
      case 'WORK':
        switch (fieldLabel) {
          case 'workType':
            return 'Loại công việc'
          case 'title':
            return 'Tiêu đề'
          case 'startDate':
            return 'Ngày bắt đầu'
          case 'dueDate':
            return 'Ngày kết thúc'
          case 'status':
            return 'Trạng thái'
          case 'content':
            return 'Nội dung'
          case 'important':
            return 'Độ ưu tiên'
          case 'assignees':
            return 'Vai trò'
          case 'files':
            return 'Tài liệu đính kèm'
          default:
            return fieldLabel
        }
    }
  },
}

const work_type = {
  toText: enumKey => {
    return WORK_TYPE[enumKey]
  },
}

const work_status = {
  toText: enumKey => {
    switch (enumKey) {
      case WORK_STATUS.PENDING:
        return 'Đang thực hiện'
      case WORK_STATUS.COMPLETE:
        return 'Hoàn thành'
    }
  },
}

const work_important = {
  toText: value => {
    return value ? 'Ưu tiên' : 'Không ưu tiên'
  },
}

export default common_history_log
export {
  assign_type,
  field_history_log,
  work_type,
  work_status,
  work_important,
}
