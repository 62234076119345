import React from 'react'
import { Modal } from 'antd'
import MissionEditForm from '../MissionEditPage/MissionEditForm'
import { inject, observer } from 'mobx-react'

const MissionFullUpdateModal = props => {
  const {
    isVisibleMissionFullUpdateModal,
    handleCloseUpdateMissionModal,
    handleAfterUpdate,
    taskCode,
  } = props
  const onClearForm = React.useRef(null)

  return (
    <Modal
      width={1000}
      style={{ top: 40 }}
      title={'Chỉnh sửa nhiệm vụ'}
      visible={isVisibleMissionFullUpdateModal}
      maskClosable={false}
      onCancel={handleCloseUpdateMissionModal}
      footer={null}>
      <MissionEditForm
        handleAfterUpdate={handleAfterUpdate}
        handleCancelUpdate={() => handleCloseUpdateMissionModal('REFRESH')}
        taskCode={taskCode}
      />
    </Modal>
  )
}

MissionFullUpdateModal.propTypes = {}

export default inject('selectPeopleStore')(observer(MissionFullUpdateModal))
