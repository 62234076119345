import React, { Fragment, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Empty,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
} from 'antd'
import { inject, observer } from 'mobx-react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { toJS } from 'mobx'
import AddAccountForm from './AddAccountForm'
import { find } from 'lodash-es'

const AccountEditingDrawer = props => {
  const {
    drawerVisible,
    onClose,
    selectedAccount, //this is service, not user account
    userStore,
    loadingAnimationStore,
    accountEditStore,
    authenticationStore,
  } = props

  const { selectedUser } = userStore
  const { accountList } = accountEditStore

  const [currentAccount, setCurrentAccount] = useState(undefined)
  const [showAddAccount, setShowAddAccount] = useState(false)

  const [form] = Form.useForm()

  const onFinish = async values => {
    try {
      loadingAnimationStore.showSpinner(true)

      let userAccount = find(
        accountList,
        item => item.command.code === selectedAccount.code
      )

      if (userAccount) {
        await authenticationStore.testLoginTruc(
          values.account_name,
          values.password
        )

        await accountEditStore.updateAccountById(userAccount.id, values)
        message.success('Cập nhật thông tin thành công!')

        handleCloseDrawer()
      } else {
        message.error('Không tìm thấy tài khoản')
      }
    } catch (e) {
      message.error(e.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleDeleteAccount = async () => {
    loadingAnimationStore.showSpinner(true)

    try {
      let userAccount = find(
        accountList,
        item => item.command.code === selectedAccount.code
      )
      if (userAccount) {
        await accountEditStore.deleteAccountById(userAccount.id)
        await accountEditStore.getAccountsByUserCode(selectedUser.code)

        message.success('Xoá tài khoản thành công!')

        handleCloseDrawer()
      } else {
        message.error('Không tìm thấy tài khoản')
      }
    } catch (e) {
      message.error(e.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleCloseDrawer = () => {
    onClose()
    setShowAddAccount(false)
  }

  useEffect(() => {
    if (accountList && selectedUser && selectedAccount) {
      const accountArr = toJS(accountList)
        .map(account => {
          return {
            id: account.id,
            password: account.password,
            account_name: account.account_name,
            command_url: account.command.url,
            command_code: account.command.code,
          }
        })
        .filter(item => item.command_code === selectedAccount.code)

      if (accountArr.length === 0) {
        setCurrentAccount(undefined)
        return
      }

      form.setFieldsValue({
        account_name: accountArr.length !== 0 ? accountArr[0].account_name : '',
        password: accountArr.length !== 0 ? accountArr[0].password : '',
      })

      setCurrentAccount(accountArr)
    }
  }, [accountList, selectedUser, selectedAccount])

  return (
    <>
      {!showAddAccount ? (
        currentAccount ? (
          <Form
            form={form}
            scrollToFirstError
            name={'account-edit-form'}
            onFinish={onFinish}
            layout={'vertical'}>
            <Form.Item
              label={'Tên tài khoản'}
              name={'account_name'}
              rules={[
                { required: true, message: 'Vui lòng nhập tên tài khoản' },
              ]}>
              <Input
                autoComplete={'new-password'}
                placeholder={'Tên tài khoản'}
              />
            </Form.Item>
            <Form.Item
              label={'Mật khẩu'}
              name={'password'}
              rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}>
              <Input.Password
                autoComplete={'new-password'}
                placeholder={'Mật khẩu'}
              />
            </Form.Item>

            <Row
              type={'flex'}
              style={{ marginTop: 30 }}
              justify={'space-between'}
              gutter={15}>
              <Col span={12}>
                <Popconfirm
                  placement={'topLeft'}
                  onConfirm={handleDeleteAccount}
                  icon={
                    <QuestionCircleOutlined
                      style={{ color: 'rgb(244, 67, 54)' }}
                    />
                  }
                  okType={'danger'}
                  okText="Xoá tài khoản"
                  cancelText="Không"
                  title={'Bạn có chắc chắn muốn xoá tài khoản này?'}>
                  <Button block type={'danger'}>
                    Xoá tài khoản
                  </Button>
                </Popconfirm>
              </Col>
              <Col span={12}>
                <Button block type={'primary'} htmlType="submit">
                  Lưu thay đổi
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Fragment>
            <Empty description={'Chưa có tài khoản'} />
            <Button
              onClick={() => setShowAddAccount(true)}
              block
              type={'primary'}
              style={{ marginTop: 15 }}>
              Tạo tài khoản
            </Button>
          </Fragment>
        )
      ) : (
        <AddAccountForm
          selectedAccount={selectedAccount}
          onCancel={() => setShowAddAccount(false)}
          onCloseDrawer={() => handleCloseDrawer()}
        />
      )}
    </>
  )
}

export default inject(
  'userStore',
  'loadingAnimationStore',
  'accountEditStore',
  'authenticationStore',
)(observer(AccountEditingDrawer))
