import {
  CheckCircleFilled,
  DeleteOutlined,
  ExclamationCircleFilled,
  FieldTimeOutlined,
  FormOutlined,
  InfoCircleFilled,
  QuestionCircleOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { List, message, Popconfirm, Progress, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { blue, yellowPrimary } from '../../color'
import utils from '../../utils'
import { EmptyText } from '../MissionCreatePage/ModalDocumentListStyled'
import { ButtonAction, IconWrapper } from './MissionPageStyled'
import { inject, observer } from 'mobx-react'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'

const renderProgress = completion_rate => {
  if (completion_rate === '0' || completion_rate === null) {
    return <Progress percent={0} trailColor={'#ff4d4f'} />
  }
  if (completion_rate === '100') {
    return <Progress percent={100} />
  }
  return <Progress percent={parseInt(completion_rate)} status="active" />
}

const renderIcon = completion_rate => {
  let Icon = <QuestionCircleOutlined style={{ color: '#ff4d4f' }} />
  if (completion_rate === '0' || completion_rate === null) {
    Icon = <ExclamationCircleFilled style={{ color: '#ff4d4f' }} />
  }
  if (completion_rate === '100') {
    Icon = <CheckCircleFilled style={{ color: '#52c41a' }} />
  }
  if (
    completion_rate !== '100' &&
    completion_rate !== '0' &&
    completion_rate !== null
  ) {
    Icon = <InfoCircleFilled style={{ color: '#1890ff' }} />
  }
  return <IconWrapper>{Icon}</IconWrapper>
}

const MissionPageListItem = props => {
  const { item, taskStore, handleNextMonth, activeTab } = props

  const [taskItem, setTaskItem] = useState({})

  useEffect(() => {
    setTaskItem(item)
  }, [item])

  const getNumberTask = (originNumber, type) => {
    if (type === 'UP') {
      return originNumber + 1
    }
    if (type === 'DOWN') {
      if (originNumber === 0) return 0
      return originNumber - 1
    }
    return originNumber
  }

  useEffect(() => {
    if (
      !taskStore.updateTaskItem.status ||
      !item.task_code.includes(taskStore.updateTaskItem.task_code)
    )
      return
    setTaskItem({
      ...item,
      processing: getNumberTask(
        taskItem.processing,
        taskStore.updateTaskItem.processing
      ),
      completed: getNumberTask(
        taskItem.completed,
        taskStore.updateTaskItem.completed
      ),
      chua_thuc_hien: getNumberTask(
        taskItem.chua_thuc_hien,
        taskStore.updateTaskItem.chua_thuc_hien
      ),
      ket_qua: {
        ...item.ket_qua,
        phan_tram_hoan_thanh:
          taskStore.updateTaskItem.phan_tram_hoan_thanh + '' || '0',
        isCompleted:
          taskStore.updateTaskItem.phan_tram_hoan_thanh + '' === '100',
      },
      isCompleted: true,
    })
    if (activeTab === 'ALL') return
    ;(async () => {
      await taskStore.getCounterTask()
    })()
  }, [taskStore.updateTaskItem])

  const [isLoadingTaskItem, setIsLoadingTaskItem] = useState(false)

  const onToggleTaskDetail = () => {
    if (taskItem.isUser) return
    props.onToggleTaskDetail(taskItem.id)
  }
  const handleDeleteTask = async codeTask => {
    try {
      setIsLoadingTaskItem(true)
      await taskStore.deleteTask(codeTask)
      await taskStore.getTaskList()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      message.success('Đã xóa nhiệm vụ thành công!')
      setIsLoadingTaskItem(false)
    }
  }

  const renderActions = () => {
    const actions = []
    const UpdateBtn = (
      <Tooltip title="Cập nhật">
        <ButtonAction
          onClick={e => {
            e.stopPropagation()
            // handleOpenUpdateTaskModal(taskItem.task_code)
          }}>
          <FormOutlined style={{ color: blue }} />
        </ButtonAction>
      </Tooltip>
    )
    const DeleteBtn = (
      <AccessControlAction aclActionType={ACL_ACTION_TYPE.delete__MISSION}>
        <Popconfirm
          title="Bạn có muốn xóa nhiệm vụ này?"
          onConfirm={async e => {
            e.stopPropagation()
            await handleDeleteTask(taskItem.task_code)
          }}
          onCancel={e => e.stopPropagation()}
          okText="Đồng ý"
          cancelText="Không"
          placement="topRight">
          <Tooltip title="Xóa">
            <ButtonAction onClick={e => e.stopPropagation()}>
              <DeleteOutlined style={{ color: '#ff4d4d' }} />
            </ButtonAction>
          </Tooltip>
        </Popconfirm>
      </AccessControlAction>
    )
    // ẩn button update ở list
    actions.push(DeleteBtn)

    return actions
  }

  const renderAvatar = () => {
    if (!taskItem.isUser) {
      return null
    }
    return (
      <div
        style={{
          width: 22,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <UserOutlined style={{ fontSize: 14, color: blue }} />
      </div>
    )
  }

  const renderContent = () => {
    if (!taskItem.noi_dung) {
      return <EmptyText>Không có nội dung</EmptyText>
    }
    return (
      <>
        {utils
          .removeHtml(taskItem.noi_dung)
          .split(' ')
          .map((item, index) => {
            return (
              <span
                key={index}
                style={{
                  display: 'inline-block',
                  marginRight: 3,
                }}>
                {item}
              </span>
            )
          })}
      </>
    )
  }

  const renderUserContent = (
    <>
      <div style={{ width: 160, marginLeft: 25 }}>
        <div style={{ lineHeight: '20px' }}>
          <FieldTimeOutlined style={{ color: yellowPrimary, marginRight: 4 }} />
          Thời gian
          {taskItem.overdue && (
            <>
              <WarningOutlined
                style={{
                  marginLeft: 8,
                  marginRight: 4,
                  color: '#dc2626',
                }}
              />
              <span style={{ color: '#dc2626' }}>Quá hạn</span>
            </>
          )}
        </div>
        <div style={{ lineHeight: '20px' }}>
          {`${
            taskItem.ngay_bat_dau
              ? moment(taskItem.ngay_bat_dau).format('DD/MM/YYYY')
              : 'Không rõ'
          } 
        -
        ${
          taskItem.ngay_ket_thuc
            ? moment(taskItem.ngay_ket_thuc).format('DD/MM/YYYY')
            : 'Không rõ'
        }`}
        </div>
        {!taskItem.isCompleted && (
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.chuyen_sang_thang__MISSION}>
            <Tooltip title={'Hiện thị nhiệm vụ sang tháng sau'}>
              <Popconfirm
                title="Bạn có muốn chuyển nhiệm vụ này sang tháng sau?"
                onConfirm={e => {
                  e.stopPropagation()
                  handleNextMonth(taskItem.task_code)
                }}
                okText="Đồng ý"
                cancelText="Không">
                <div
                  onClick={async e => {
                    e.stopPropagation()
                  }}
                  style={{
                    textDecoration: 'underline',
                    color: blue,
                  }}>
                  Chuyển sang tháng sau
                </div>
              </Popconfirm>
            </Tooltip>
          </AccessControlAction>
        )}
      </div>
      <div style={{ width: 120 }}>
        {renderProgress(taskItem?.ket_qua?.phan_tram_hoan_thanh)}
      </div>
    </>
  )

  const renderNonUserConent = (
    <>
      <div style={{ minWidth: 380 }}>
        <span>
          <CheckCircleFilled style={{ color: '#52c41a', marginRight: 4 }} />
          Hoàn thành:{' '}
          <span style={{ fontWeight: 500 }}>{taskItem.completed}</span>
        </span>
        <span
          style={{
            padding: '0 10px',
            margin: '0 10px',
            borderLeft: `2px solid  #a0bbdc`,
            borderRight: '2px solid  #a0bbdc',
          }}>
          <InfoCircleFilled style={{ color: '#1890ff', marginRight: 4 }} />
          Đang thực hiện:{' '}
          <span style={{ fontWeight: 500 }}>{taskItem.processing}</span>
        </span>
        <span>
          <ExclamationCircleFilled
            style={{ color: '#ff4d4f', marginRight: 4 }}
          />
          Chưa thực hiện:{' '}
          <span style={{ fontWeight: 500 }}>{taskItem.chua_thuc_hien}</span>
        </span>
      </div>
    </>
  )

  return (
    <List.Item
      className={`task-item`}
      style={{
        backgroundColor: `${taskItem.isUser ? '#E9F0FD' : '#FFFFFFF'} `,
      }}
      onClick={onToggleTaskDetail}
      actions={!taskItem.isUser && renderActions()}>
      <List.Item.Meta
        style={{
          alignItems: 'center',
        }}
        avatar={renderAvatar()}
        title={taskItem.isUser ? taskItem.ten_don_vi : null}
        description={
          taskItem.isUser ? null : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 20,
              }}>
              {renderIcon(taskItem.ket_qua?.phan_tram_hoan_thanh)}
              <Paragraph
                style={{ marginBottom: 0 }}
                ellipsis={{
                  rows: 3,
                  suffix: (
                    <span
                      className={'view-detail-btn'}
                      style={{
                        marginLeft: 8,
                        fontWeight: 500,
                        fontStyle: 'italic',
                      }}>
                      Xem chi tiết
                    </span>
                  ),
                }}>
                {renderContent()}
              </Paragraph>
            </div>
          )
        }
      />
      {!taskItem.isUser && renderUserContent}
      {taskItem.isUser && renderNonUserConent}
      <SpinnerInlineComponent
        isLoading={isLoadingTaskItem}
        noBackground
        tip={'Đang xóa...'}
      />
    </List.Item>
  )
}

MissionPageListItem.propTypes = {}
export default inject(
  'taskStore',
  'authenticationStore',
)(observer(MissionPageListItem))
