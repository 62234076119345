import React, { memo } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Container } from '../../../layouts/Container/Container'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import InternalDocumentOutgoingDocCreateForm from './InternalDocumentOutgoingDocCreateForm'
import { useHistory, useLocation } from 'react-router-dom'

const InternalDocumentOutgoingDocCreatePage = props => {
  const { authenticationStore } = props

  const history = useHistory()
  const location = useLocation()

  const isDiNoiBo = location.pathname.includes("di-noi-bo")

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo văn bản đi {isDiNoiBo ? 'nội bộ' : ''} | Quản lý VB Nghệ Tĩnh</title>
      </Helmet>
      <PageTitle location={props.location} title={`Tạo văn bản đi ${isDiNoiBo ? 'nội bộ' : ''}`}/>
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <InternalDocumentOutgoingDocCreateForm
            isDiNoiBo={isDiNoiBo}
            handleCancel={() => {
              history.push(isDiNoiBo ? '/internal-document/di-noi-bo' : '/internal-document/outgoing-document')
            }}
          />
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

InternalDocumentOutgoingDocCreatePage.propTypes = {}

export default memo(
  inject('authenticationStore')(observer(InternalDocumentOutgoingDocCreatePage))
)
