import React from 'react'
import { Modal } from 'antd'
import MissionCreateForm from '../MissionCreatePage/MissionCreateForm'
import { inject, observer } from 'mobx-react'

const MissionFullCreateModal = props => {
  const {
    isVisibleMissionFullCreateModal,
    handleCancelCreateMission,
    selectPeopleStore,
  } = props
  const onClearForm = React.useRef(null)

  return (
    <Modal
      width={1000}
      style={{ top: 40 }}
      title={'Tạo nhiệm vụ mới'}
      visible={isVisibleMissionFullCreateModal}
      maskClosable={false}
      onCancel={() => {
        onClearForm.current()
        handleCancelCreateMission()
      }}
      footer={null}
      destroyOnClose>
      {isVisibleMissionFullCreateModal && (
        <MissionCreateForm
          noLoadSelectData={true}
          isVisibleMissionFullCreateModal={isVisibleMissionFullCreateModal}
          onClearForm={onClearForm}
          isMissionFull
          handleAfterCreatedMission={handleCancelCreateMission}
        />
      )}
    </Modal>
  )
}

MissionFullCreateModal.propTypes = {}
export default inject('selectPeopleStore')(observer(MissionFullCreateModal))
