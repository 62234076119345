import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  STATUS_DEFAULT,
  WORK_STATUS,
} from '../../../constants'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import { EmptyText } from '../../../components/Common/CellText'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
import utils from '../../../utils'
import {
  HeadingButtonWrapper,
  InfoDrawerRight,
  InfoDrawerRightWrapper,
  ListSubWork,
  SubWork,
  SubWorkTitle,
} from './DrawerWorkStyled'
import { Button, Col, Dropdown, List, Menu, message, Modal, Row } from 'antd'
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  ContainerOutlined,
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons'
import { blue, greenPrimary } from '../../../color'
import AccessControlAction from '../../../components/AccessControlAction/AccessControlAction'
import selectPeopleStore from '../../../stores/selectPeopleStore'
import loadingAnimationStore from '../../../stores/loadingAnimationStore'
import workStore from '../../../stores/workStore'
import aclStore from '../../../stores/aclStore'
import authenticationStore from '../../../stores/authenticationStore'
import queryString from 'query-string'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import htmlToPdfmake from 'html-to-pdfmake'

const { confirm } = Modal

const ContentDrawerRight = props => {
  const history = useHistory()
  const { currentUser } = authenticationStore
  const { queryParams, commentWorkList } = workStore
  const [disableChange, setDisableChange] = useState(true)
  const {
    screenWidth,
    breakpoint,
    work_type,
    handlerList,
    followerList,
    attachments,
    outgoingDocument,
    parent_id,
    status,
    subwork,
    handleOpenWorkDetail,
    setIsVisiblePopupWorkForm,
    workList,
    code,
    assignees,
    openPopupUpdateWork,
    onCloseDrawerWork,
    openPopupAddPersonWork,
    openPopupElectronicRelease,
    workDetail,
    setDocumentHistory,
    setIsVisibleHistory,
    title,
  } = props

  useEffect(() => {
    let disable = false;
    commentWorkList && commentWorkList.forEach(comment => {
      console.log(comment.isHidden)
      if (!comment.deleted) {
        disable = true
      }
    })
    setDisableChange(disable)
  }, [commentWorkList])

  const handleUpdateStatusWork = async status => {
    const parentId = utils.getParameterByName('parent_id')
    loadingAnimationStore.setTableLoading(true)
    loadingAnimationStore.setShowSpinInline(true)
    try {
      await workStore.updateStatusWork(code, status)
      const response = await workStore.getWorkDetail(code)
      if (parentId) {
        await workStore.getListSubWork(parentId)
      } else {
        const newWorkList = [...workList]
        const indexParentTarget = workList.findIndex(item => item.code === code)
        if (indexParentTarget !== -1) {
          newWorkList[indexParentTarget] = {
            ...response.data,
            has_subwork: response.data.subwork?.length > 0,
          }
        }
        workStore.setWorkList(newWorkList)
      }
      if (status === STATUS_DEFAULT.COMPLETE) {
        return message.success('Bạn đã đánh dấu công việc hoàn thành!')
      }
      message.success('Bạn đã đánh dấu công việc là đang thực hiện!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.setTableLoading(false)
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const showConfirmDeleteWork = () => {
    confirm({
      title: 'Bạn có muốn xóa công việc này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteWork()
      },
    })
  }

  const handleOpenPopupAddPersonWork = () => {
    openPopupAddPersonWork()
    const userList = assignees
      ? assignees.filter(
          el =>
            el.assignee_type === ASSIGNEE_TYPE.USER &&
            el.permission !== ASSIGNEE_TYPE.CREATE
        )
      : []
    const departmentList = assignees
      ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT)
      : []
    const groupList = assignees
      ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP)
      : []
    selectPeopleStore.setSelectUserData(
      selectPeopleStore.convertSelectDataObj(userList)
    )
    selectPeopleStore.setSelectDepartmentData(
      selectPeopleStore.convertSelectDataObj(departmentList)
    )
    selectPeopleStore.setSelectGroupData(
      selectPeopleStore.convertSelectDataObj(groupList)
    )
  }

  const handleDeleteWork = async () => {
    const parentId = utils.getParameterByName('parent_id')
    loadingAnimationStore.showSpinner(true)
    try {
      await workStore.deleteWork(code)
      if (parentId) {
        await workStore.getListSubWork(parentId)
        await workStore.getWorkDetail(parentId)
        window.history.replaceState(null, null, `/works/view/${parentId}`)
      } else {
        const indexParentTarget = workList.findIndex(item => item.code === code)
        workList.splice(indexParentTarget, 1)
        onCloseDrawerWork()
        const queryStringStringify = queryString.stringify(
          {
            ...queryParams,
            parent_id: null,
          },
          {
            skipNull: true,
          }
        )
        window.history.replaceState(
          null,
          null,
          `/works?${queryStringStringify}`
        )
      }
      message.success('Xóa công việc thành công!')
    } catch (err) {
      console.log(err)
      if (err?.errorCode === 'WORK-106') {
        message.error('Công việc này đã được gán ở KPI nên không thể xóa')
      } else message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const exportWork = () => {
    const pdfContent = document.getElementById('divToPrint')
    //https://www.npmjs.com/package/html-to-pdfmake
    const html = htmlToPdfmake(pdfContent.innerHTML, {
      imagesByReference: true,
    })

    const documentDefinition = { content: html.content, images: html.images }
    const originURL = window.location.origin
    pdfMake.fonts = {
      TimesNewRoman: {
        normal: `${originURL}/assets/fonts/timesNewRoman/Times-New-Roman-Regular.ttf`,
        bold: `${originURL}/assets/fonts/timesNewRoman/Times-New-Roman-Bold.ttf`,
        italics: `${originURL}/assets/fonts/timesNewRoman/Times-New-Roman-Italic.ttf`,
        bolditalics: `${originURL}/assets/fonts/timesNewRoman/Times-New-Roman-BoldItalic.ttf`,
      },
      Roboto: {
        normal: `${originURL}/assets/fonts/roboto/Roboto-Regular.ttf`,
        bold: `${originURL}/assets/fonts/roboto/Roboto-Bold.ttf`,
        italics: `${originURL}/assets/fonts/roboto/Roboto-Italic.ttf`,
        bolditalics: `${originURL}/assets/fonts/roboto/Roboto-BoldItalic.ttf`,
      },
      CourierNew: {
        normal: `${originURL}/assets/fonts/courierNew/Courier-New-Regular.ttf`,
        bold: `${originURL}/assets/fonts/courierNew/Courier-New-Bold.ttf`,
        italics: `${originURL}/assets/fonts/courierNew/Courier-New-Italic.ttf`,
        bolditalics: `${originURL}/assets/fonts/courierNew/Courier-New-BoldItalic.ttf`,
      },
      Arial: {
        normal: `${originURL}/assets/fonts/arial/Arial-Regular.ttf`,
        bold: `${originURL}/assets/fonts/arial/Arial-Bold.ttf`,
        italics: `${originURL}/assets/fonts/arial/Arial-Italic.ttf`,
        bolditalics: `${originURL}/assets/fonts/arial/Arial-BoldItalic.ttf`,
      },
    }
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.createPdf(documentDefinition).download(`${title}.pdf`)
  }

  const menuButton = (
    <Menu>
      <>
        {status !== STATUS_DEFAULT.COMPLETE &&
          aclStore.checkAccessControlAction(
            utils.getParameterByName('parent_id')
              ? ACL_ACTION_TYPE.sua_cong_viec_phu__WORK
              : ACL_ACTION_TYPE.edit__WORK
          ) && (
            <Menu.Item
              key="edit"
              icon={<EditOutlined style={{ color: blue }} />}
              onClick={() => {
                openPopupUpdateWork()
                const userList = assignees
                  ? assignees.filter(
                      el =>
                        el.assignee_type === ASSIGNEE_TYPE.USER &&
                        el.permission !== ASSIGNEE_TYPE.CREATE
                    )
                  : []
                const departmentList = assignees
                  ? assignees.filter(
                      el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT
                    )
                  : []
                const groupList = assignees
                  ? assignees.filter(
                      el => el.assignee_type === ASSIGNEE_TYPE.GROUP
                    )
                  : []
                selectPeopleStore.setSelectUserData(
                  selectPeopleStore.convertSelectDataObj(userList)
                )
                selectPeopleStore.setSelectDepartmentData(
                  selectPeopleStore.convertSelectDataObj(departmentList)
                )
                selectPeopleStore.setSelectGroupData(
                  selectPeopleStore.convertSelectDataObj(groupList)
                )
              }}
              disabled={disableChange}
              >
              <span style={{ color: blue }}>Sửa công việc</span>
            </Menu.Item>
          )}

        {status !== STATUS_DEFAULT.COMPLETE &&
          aclStore.checkAccessControlAction(
            ACL_ACTION_TYPE.them_nguoi__WORK
          ) && (
            <Menu.Item
              key="add_assignee"
              icon={<PlusCircleOutlined style={{ color: blue }} />}
              onClick={() => {
                handleOpenPopupAddPersonWork()
              }}>
              <span style={{ color: blue }}>Thêm người</span>
            </Menu.Item>
          )}

        {status !== STATUS_DEFAULT.COMPLETE &&
          !parent_id &&
          aclStore.checkAccessControlAction(
            ACL_ACTION_TYPE.phat_hanh__WORK
          ) && (
            <Menu.Item
              key="publish_document"
              icon={<ContainerOutlined style={{ color: blue }} />}
              onClick={() => {
                openPopupElectronicRelease()
              }}>
              <span style={{ color: blue }}>Phát hành điện tử</span>
            </Menu.Item>
          )}

        <Menu.Item
          key="history"
          icon={<HistoryOutlined style={{ color: blue }} />}
          onClick={() => {
            setDocumentHistory({
              code: workDetail.code,
            })
            setIsVisibleHistory(true)
          }}>
          <span style={{ color: blue }}>Lịch sử</span>
        </Menu.Item>

        <Menu.Item
          key="print"
          icon={<PrinterOutlined style={{ color: blue }} />}
          onClick={() => {
            exportWork()
          }}>
          <span style={{ color: blue }}>In</span>
        </Menu.Item>

        {status !== STATUS_DEFAULT.COMPLETE &&
          currentUser?.username ===
            assignees?.find(el => el.permission === ASSIGNEE_TYPE.CREATE)
              .assignee_code &&
          aclStore.checkAccessControlAction(
            utils.getParameterByName('parent_id')
              ? ACL_ACTION_TYPE.xoa_cong_viec_phu__WORK
              : ACL_ACTION_TYPE.delete__WORK
          ) && (
            <Menu.Item
              key="delete"
              danger
              icon={<DeleteOutlined />}
              onClick={showConfirmDeleteWork}
              disabled={disableChange}>
              <span>Xóa công việc</span>
            </Menu.Item>
          )}
      </>
    </Menu>
  )

  return (
    <InfoDrawerRightWrapper isMobile={screenWidth < breakpoint}>
      <Row gutter={8}>
        <Col flex={'auto'}>
          {status !== STATUS_DEFAULT.COMPLETE ? (
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              onClick={() => handleUpdateStatusWork(STATUS_DEFAULT.COMPLETE)}
              style={{
                backgroundColor: '#3aaf50',
                borderColor: '#3aaf50',
                width: '100%',
              }}>
              Hoàn thành
            </Button>
          ) : (
            <Button
              icon={<RetweetOutlined />}
              style={{ width: '100%' }}
              onClick={() => handleUpdateStatusWork(STATUS_DEFAULT.PENDING)}>
              Chưa hoàn thành
            </Button>
          )}
        </Col>
        <Col>
          <HeadingButtonWrapper>
            <Dropdown overlay={menuButton} trigger={['click']}>
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </HeadingButtonWrapper>
        </Col>
      </Row>
      <InfoDrawerRight>
        <dt>
          {work_type === WORK_STATUS.ELECTRONIC
            ? 'Lãnh đạo ký duyệt'
            : 'Người xử lý'}
        </dt>
        <dd>
          {handlerList.length > 0 ? (
            <PopoverWatchTime
              userList={handlerList}
              title={
                work_type === WORK_STATUS.ELECTRONIC
                  ? 'Lãnh đạo ký duyệt'
                  : 'Danh sách người xử lý'
              }
            />
          ) : (
            <EmptyText>Không có.</EmptyText>
          )}
        </dd>
        <dt>Người theo dõi</dt>
        <dd>
          {followerList.length > 0 ? (
            <PopoverWatchTime
              userList={followerList}
              title={'Danh sách người theo dõi'}
            />
          ) : (
            <EmptyText>Không có người theo dõi.</EmptyText>
          )}
        </dd>
        <dt>Tài liệu đính kèm</dt>
        <dd>
          {attachments && attachments.length > 0 ? (
            attachments.map(file => (
              <FileItem
                key={file.file_id}
                file_id={file.file_id}
                file_name={file.file_name}
                file_type={utils.getExtensionFile(file.file_name)}
              />
            ))
          ) : (
            <EmptyText>Không có tài liệu đính kèm.</EmptyText>
          )}
        </dd>
        {outgoingDocument && (
          <>
            <dt>Văn bản liên kết</dt>
            <dd
              onClick={() =>
                history.push(
                  `/internal-document/outgoing-document/view/${outgoingDocument.document_code}`
                )
              }>
              <a>{outgoingDocument.document_name}</a>
            </dd>
          </>
        )}
      </InfoDrawerRight>
      <div
        style={{
          height: 0.5,
          backgroundColor: '#f0f0f0',
          marginBottom: 10,
        }}
      />
      {!parent_id && (
        <SubWork>
          <SubWorkTitle>
            <span>
              Công việc phụ:
              {status !== STATUS_DEFAULT.COMPLETE && (
                <AccessControlAction
                  aclActionType={ACL_ACTION_TYPE.them_cong_viec_phu__WORK}>
                  <PlusCircleOutlined
                    style={{ cursor: 'pointer', color: blue, marginLeft: 6 }}
                    onClick={() => {
                      selectPeopleStore.clearSelected()
                      setIsVisiblePopupWorkForm(true)
                    }}
                  />
                </AccessControlAction>
              )}
            </span>
          </SubWorkTitle>
          <ListSubWork
            size={'small'}
            locale={{
              emptyText: <EmptyText>Không có công việc phụ</EmptyText>,
            }}
            dataSource={subwork}
            renderItem={item => (
              <List.Item
                key={item.code}
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenWorkDetail(item.code)}>
                <div>
                  {item.status === STATUS_DEFAULT.COMPLETE ? (
                    <CheckCircleFilled
                      style={{ marginRight: 8, color: greenPrimary }}
                    />
                  ) : (
                    <CheckCircleOutlined style={{ marginRight: 8 }} />
                  )}
                  {item.title}
                </div>
              </List.Item>
            )}
          />
        </SubWork>
      )}
    </InfoDrawerRightWrapper>
  )
}

ContentDrawerRight.propTypes = {}

export default ContentDrawerRight
