import {
  FileTextOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  message,
  Row,
  Tabs,
  Tooltip,
} from 'antd'
import axios from 'axios'
import { runInAction, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Transition } from 'react-transition-group'
import { EmptyText, UrlifiedText } from '../../components/Common/CellText'
import EmptyContent from '../../components/EmptyContent'
import IncompleteListModal from '../../components/IncompleteListModal/IncompleteListModal'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import UnreadDocModal from '../../components/UnreadDocumentModal/UnreadDocModal'
import { useSSO } from '../../config'
import { ASSIGNEE_TYPE, DD_MM, HHhMM, ISO_DATE_FORMAT } from '../../constants'
import date_utils from '../../date_utils'
import DashboardLayout from '../../layouts/DashboardLayout'
import utils from '../../utils'
import validator from '../../validator'
import { LoginWrapper } from '../PortalPageV2/PortalPageV2Styled'
import {
  BoxChartLoading,
  CardWrapper,
  ColWrapper,
  DayTab,
  DescriptionLink,
  ItemCalendarContent,
  StatisticList,
  WorkScheduledCard,
  WorkScheduledItem,
} from './DashboardPageV2Styled'

const { TabPane } = Tabs
const btnPrevAndNextStyled = {
  cursor: 'pointer',
}

const DashboardPageV2 = props => {
  const defaultStyle = {
    transition: `all 500ms cubic-bezier(.4,0,.2,1)`,
    opacity: 0,
    margin: 0,
    maxHeight: 0,
  }

  const transitionStyles = {
    entering: { padding: 0, maxHeight: 0, opacity: 0 },
    entered: { maxHeight: 5000, opacity: 1 },
    exiting: { padding: 0, maxHeight: 0, opacity: 0 },
    exited: { padding: 0, maxHeight: 0, opacity: 0 },
  }

  const {
    history,
    location,
    workScheduleStore,
    statisticStore,
    fileStore,
    authenticationStore,
    accountStore,
    commandStore,
    notificationStore,
    userStore,
    moduleStore,
    aclStore,
    taskStore,
    workStore,
  } = props

  const { currentUser } = authenticationStore
  const { workScheduleList } = workScheduleStore
  const { statistic } = statisticStore

  const [days, setDays] = useState(date_utils.weekRange(date_utils.current()))
  const [dataCongViec, setDataCongViec] = useState([{ type: '', value: 0 }])
  const [position, setPosition] = useState(['left', 'right'])
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false)
  // const [isLoadingChart, setIsLoadingChart] = useState(false)
  const [isLoadingIncomingList, setIsLoadingIncomingList] = useState(false)
  const [isLoadingOutgoingList, setIsLoadingOutgoingList] = useState(false)
  //
  const [isShowUnreadIncoming, setIsShowUnreadIncoming] = useState(false)
  const [isShowUnreadOutgoing, setIsShowUnreadOutgoing] = useState(false)
  const [isShowIncompleteWork, setIsInCompleteWork] = useState(false)
  const [isShowIncompleteTask, setIsInCompleteTask] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [, setShowDialog] = useState(false)

  let sumCongViec = 0

  dataCongViec.map(data => (sumCongViec += data.value))

  const dataNhiemVu = [
    {
      type: 'Chưa hoàn thành',
      value: statistic
        ? statistic.task_manager_processing.info +
          statistic.task_manager_request.info
        : 0,
    },
    {
      type: 'Hoàn thành',
      value: statistic ? statistic.task_manager_complete.info : 0,
    },
  ]
  let sumNhiemVu = 0
  dataNhiemVu.map(data => (sumNhiemVu += data.value))

  const workScheduleOfDay = strDay =>
    workScheduleList.filter(
      workSchedule => moment(workSchedule.start_at).format(DD_MM) === strDay
    )

  const [isLoadingTaskList, setLoadingTaskList] = useState(false)
  const [isLoadingWorkList, setIsLoadingWorkList] = useState(false)
  const [
    currentViewingWorkScheduleCodes,
    setCurrentViewingWorkScheduleCodes,
  ] = useState([])

  useEffect(() => {
    if (!currentUser) return

    runInAction(() => {
      taskStore.missionQueryParams = {
        ...taskStore.missionQueryParams,
        assignee: `assignee=${encodeURIComponent(currentUser.username)},${
          ASSIGNEE_TYPE.USER
        },${ASSIGNEE_TYPE.HANDLER}`,
        tien_do: '002',
      }
    })

    const source = axios.CancelToken.source()
    ;(async () => {
      try {
        setLoadingTaskList(true)
        await taskStore.getTaskList(source.token)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
        setLoadingTaskList(false)
      } finally {
        setLoadingTaskList(false)
      }
    })()
    return () => {
      source.cancel()
      taskStore.clearTaskStoreFilter()
      taskStore.clearTaskList()
    }
  }, [currentUser])

  useEffect(() => {
    workStore.setQueryParams({
      ...workStore.queryParams,
      size: 100,
      permission: ASSIGNEE_TYPE.HANDLER,
      parent_id: null,
      status: 'PENDING',
    })
    ;(async () => {
      try {
        setIsLoadingWorkList(true)
        await workStore.getIncompleteWorkList()
        workStore.clearQueryParams()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
        setIsLoadingWorkList(false)
      } finally {
        setIsLoadingWorkList(false)
      }
    })()

    return () => {
      workStore.clearQueryParams()
    }
  }, [])

  const renderTaskListItem = taskItem => {
    if (!taskItem.noi_dung) {
      return 'Không có nội dung'
    }
    return utils.removeHtml(taskItem.noi_dung)
  }

  const handleChangeWeek = async (date, type) => {
    let fromDate = null
    let toDate = null
    if (type === 'prev') {
      fromDate = moment(date[0]).subtract(7, 'days').format(ISO_DATE_FORMAT)
      toDate = moment(date[6]).subtract(7, 'days').format(ISO_DATE_FORMAT)
      setDays(date_utils.weekRange(moment(date[0]).subtract(7, 'days')))
    }
    if (type === 'next') {
      fromDate = moment(date[0]).add(7, 'days').format(ISO_DATE_FORMAT)
      toDate = moment(date[6]).add(7, 'days').format(ISO_DATE_FORMAT)
      setDays(date_utils.weekRange(moment(date[0]).add(7, 'days')))
    }
    if (type === 'week') {
      fromDate = date_utils.weekRange(date)[0]
      toDate = date_utils.weekRange(date)[6]
      setDays(date_utils.weekRange(date))
    }
    try {
      setIsLoadingCalendar(true)
      await workScheduleStore.getWorkSchedule(
        moment(fromDate).format(ISO_DATE_FORMAT),
        moment(toDate).format(ISO_DATE_FORMAT)
      )
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingCalendar(false)
    }
  }
  const OperationsSlot = {
    left: <span style={{ fontSize: 18, fontWeight: 500, marginRight: 30 }} />,
    right: (
      <div style={{ marginBottom: '10px' }}>
        <Tooltip title={'Tuần trước'}>
          <Button
            onClick={() => handleChangeWeek(days, 'prev')}
            icon={<LeftOutlined />}
          />
        </Tooltip>
        <DatePicker
          allowClear={false}
          onChange={date => handleChangeWeek(date, 'week')}
          picker="week"
          placeholder={'Chọn tuần'}
          format={'Tuần w, YYYY'}
          value={moment(days[0])}
          style={{ height: 32, width: 120, margin: '0 10px 0 10px' }}
        />
        <Tooltip title={'Tuần sau'}>
          <Button
            onClick={() => handleChangeWeek(days, 'next')}
            icon={<RightOutlined />}
          />
        </Tooltip>
      </div>
    ),
  }

  useEffect(() => {
    if (statistic) {
      setDataCongViec([
        {
          type: 'Chưa hoàn thành',
          value: statistic ? statistic.work_manager_processing.info : 0,
        },
        {
          type: 'Hoàn thành',
          value: statistic.work_manager_complete.info,
        },
      ])
    }
  }, [statistic])

  useEffect(() => {
    ;(async () => {
      loadStatistic(setIsLoadingIncomingList)
      loadStatistic(setIsLoadingOutgoingList)
    })()
  }, [])

  const loadStatistic = async setLoadingStateFunc => {
    setLoadingStateFunc(true)
    try {
      await statisticStore.getStatistic()
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingStateFunc(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      setIsLoadingCalendar(true)
      try {
        await workScheduleStore.getWorkSchedule(
          moment(days[0]).format(ISO_DATE_FORMAT),
          moment(days[6]).format(ISO_DATE_FORMAT)
        )
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingCalendar(false)
      }
    })()
  }, [])

  // clear workScheduleList va statistic khi ra khỏi trang dashboard
  useEffect(() => {
    return () => {
      workScheduleStore.clearWorkSchedule()
      statisticStore.clearStatistic()
    }
  }, [])

  const onClickItem = workScheduled => {
    history.push({
      pathname: `/company-work-schedule/view/${workScheduled.schedule_code}`,
      state: {
        from: location.pathname,
      },
    })
  }

  const renderFile = file_ids => {
    if (file_ids) {
      if (file_ids.length === 0) {
        return <EmptyText>Không có tài liệu.</EmptyText>
      }
      if (file_ids.length === 1) {
        return (
          <DescriptionLink>
            <FileTextOutlined style={{ color: '#40a9ff' }} />
            <a
              onClick={e =>
                fileStore.handleDownloadFile(
                  file_ids[0].file_id,
                  file_ids[0].file_title
                )
              }>
              {file_ids[0].file_title}
            </a>
          </DescriptionLink>
        )
      }

      if (file_ids.length > 1) {
        return (
          <DescriptionLink>
            <FileTextOutlined style={{ color: '#40a9ff' }} />
            <a
              onClick={e =>
                fileStore.handleDownloadFile(
                  file_ids[0].file_id,
                  file_ids[0].file_title
                )
              }>
              {file_ids[0].file_title}
            </a>
          </DescriptionLink>
        )
      }
    }
  }

  const onCloseUnreadIncoming = isRefresh => {
    setIsShowUnreadIncoming(false)

    if (isRefresh === true) {
      loadStatistic(setIsLoadingIncomingList)
    }
  }

  const onCloseUnreadOutgoing = isRefresh => {
    setIsShowUnreadOutgoing(false)

    if (isRefresh === true) {
      loadStatistic(setIsLoadingOutgoingList)
    }
  }

  const onToggleIncompleteWork = bool => event => {
    setIsInCompleteWork(bool)
  }

  const onToggleIncompleteTask = bool => event => {
    setIsInCompleteTask(bool)
  }

  const getShipScheduleContent = workScheduled => {
    const shipScheduleContent = []

    shipScheduleContent.push(
      <div style={{ display: 'flex', alignItems: 'center', gap: '32px' }}>
        <div className="work-scheduled-item__info">
          <b>Thời gian: </b>
          {workScheduled.end_at
            ? `Từ ${moment(workScheduled.start_at).format(HHhMM)} Đến ${moment(
                workScheduled.end_at
              ).format(HHhMM)}`
            : `Từ ${moment(workScheduled.start_at).format(HHhMM)}`}
        </div>

        <div className="work-scheduled-item__info">
          <b>Chủ trì: </b>
          {workScheduled.host ? (
            workScheduled.host
          ) : (
            <EmptyText>Không có chủ trì.</EmptyText>
          )}
        </div>
      </div>
    )

    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Nội dung: </b>
        <span>
          {workScheduled.event_notice ? (
            UrlifiedText(
              workScheduled.event_notice
                .replace(/<\/?[^>]+(>|$)/g, '')
                .replace(/&nbsp;/g, '')
            )
          ) : (
            <EmptyText>Không có nội dung.</EmptyText>
          )}
        </span>
      </div>
    )

    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Địa điểm: </b>
        <span>
          {workScheduled.location ? (
            UrlifiedText(workScheduled?.location)
          ) : (
            <EmptyText>Không có địa điểm.</EmptyText>
          )}
        </span>
      </div>
    )

    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Thành viên tham gia: </b>
        <span>
          {workScheduled.attenders ? (
            workScheduled.attenders
          ) : (
            <EmptyText>Không có thành viên tham gia.</EmptyText>
          )}
        </span>
      </div>
    )

    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Tài liệu: </b>
        <span>{renderFile(workScheduled?.file_ids)}</span>
      </div>
    )

    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Chuẩn bị: </b>
        <span>
          {workScheduled.preparation ? (
            workScheduled.preparation
          ) : (
            <EmptyText>Không có chuẩn bị.</EmptyText>
          )}
        </span>
      </div>
    )

    return shipScheduleContent
  }

  const onToggleWorkScheduleDetail = id => event => {
    const found = currentViewingWorkScheduleCodes.find(code => code === id)
    if (!found) {
      setCurrentViewingWorkScheduleCodes(prev => [...prev, id])
    } else {
      setCurrentViewingWorkScheduleCodes(prev =>
        prev.filter(id => id !== found)
      )
    }
  }

  const isOpenWorkScheduleDetail = id => {
    return currentViewingWorkScheduleCodes.includes(id)
  }

  const handleOk = async values => {
    setConfirmLoading(true)
    try {
      const response = await authenticationStore.userLogin(
        values.identifier,
        values.password
      )
      if (response.status === 200) {
        const res = await authenticationStore.checkCurrentUser()
        await authenticationStore.getCurrentUserAvatar()
        await commandStore.getCommandList()
        await notificationStore.getUnreadNotificationCount()
        await notificationStore.getUnreadNewsCount()
        await userStore.getMentionUserList()
        await accountStore.getCurrentUserAccount()
        await moduleStore.getModuleList()
        await aclStore.getACLDetailByUser(res.data.username)
        setShowDialog(false)
        message.success(
          `Xin chào, ${utils.getNameInCapitalize(res.data.name_uppercase)}!`
        )
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Login failed response status!')
    } finally {
      setConfirmLoading(false)
    }
  }

  const loginPage = (
    <>
      <LoginWrapper
        bgImage={`${process.env.PUBLIC_URL}/assets/photos/auth-bg-2.jpg`}>
        {useSSO ? (
          <></>
        ) : (
          <Form layout={'vertical'} name={'loginForm'} onFinish={handleOk}>
            <Form.Item style={{ textAlign: 'center' }}>
              <img
                alt="login_logo"
                height={32}
                src={`${process.env.PUBLIC_URL}/assets/photos/portal_logo_white.png`}
              />
            </Form.Item>
            <Form.Item
              label="Tên đăng nhập"
              name="identifier"
              rules={[
                { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
                { validator: validator.validateUsername },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}>
              <Input.Password />
            </Form.Item>
            <Button
              type={'primary'}
              block
              htmlType={'submit'}
              loading={confirmLoading}>
              {' '}
              Đăng nhập
            </Button>
            <Button style={{ marginTop: 8 }} type={'link'} block>
              {' '}
              Quên mật khẩu?
            </Button>
            {/*<Button style={{ marginTop: 0 }} type={'link'} block onClick={showModal}> Lịch cơ quan</Button>*/}
          </Form>
        )}
      </LoginWrapper>
    </>
  )

  return (
    <div>
      {currentUser != null ? (
        <DashboardLayout title={'Link People - Link the World'}>
          <Helmet>
            <title>Dashboard | VIMC Portal</title>
          </Helmet>
          <div style={{ position: 'relative' }}>
            <Row gutter={[16, 16]}>
              <ColWrapper span={10}>
                <CardWrapper
                  title={<b>Văn bản đến mới</b>}
                  extra={
                    statistic && statistic.unread_incoming.length !== 0 ? (
                      <a onClick={() => setIsShowUnreadIncoming(true)}>
                        Xem tất cả
                      </a>
                    ) : null
                  }>
                  {isLoadingIncomingList ? (
                    <BoxChartLoading />
                  ) : (
                    statistic && (
                      <StatisticList
                        dataSource={statistic.unread_incoming.slice(0, 4)}
                        renderItem={item => (
                          <List.Item
                            key={item.id}
                            title={`${item.document_number} - ${item.title}`}>
                            <a
                              href={`/internal-document/incoming-document/view/${item.code}`}
                              target="_blank">
                              {`${item.document_number} - ${item.title}`}
                            </a>
                          </List.Item>
                        )}
                      />
                    )
                  )}
                  <SpinnerInlineComponent
                    noBackground
                    style={{ borderRadius: 8 }}
                    sizeSpin={'small'}
                    isLoading={isLoadingIncomingList}
                    tip={'Đang tải dữ liệu...'}
                  />
                </CardWrapper>

                <CardWrapper
                  title={<b>Văn bản đi mới</b>}
                  extra={
                    statistic && statistic.unread_outgoing.length !== 0 ? (
                      <a onClick={() => setIsShowUnreadOutgoing(true)}>
                        Xem tất cả
                      </a>
                    ) : null
                  }>
                  {isLoadingOutgoingList ? (
                    <BoxChartLoading />
                  ) : (
                    statistic && (
                      <StatisticList
                        dataSource={statistic.unread_outgoing.slice(0, 3)}
                        renderItem={item => (
                          <List.Item
                            key={item.id}
                            title={`${item.document_number} - ${item.title}`}>
                            <a
                              href={`/internal-document/outgoing-document/view/${item.code}`}
                              target="_blank">
                              {`${item.document_number} - ${item.title}`}
                            </a>
                          </List.Item>
                        )}
                      />
                    )
                  )}
                  <SpinnerInlineComponent
                    noBackground
                    style={{ borderRadius: 8 }}
                    sizeSpin={'small'}
                    isLoading={isLoadingOutgoingList}
                    tip={'Đang tải dữ liệu...'}
                  />
                </CardWrapper>

                <CardWrapper
                  title={<b>Nhiệm vụ chưa hoàn thành</b>}
                  extra={
                    taskStore.taskList.length !== 0 ? (
                      <a href="/mission" target="_blank">
                        Xem tất cả
                      </a>
                    ) : null
                  }>
                  {isLoadingTaskList ? (
                    <BoxChartLoading />
                  ) : (
                    statistic && (
                      <StatisticList
                        dataSource={[...toJS(taskStore.taskList).slice(0, 3)]}
                        renderItem={item =>
                          !item.isUser && (
                            <List.Item
                              key={item.id}
                              title={renderTaskListItem(item)}>
                              {renderTaskListItem(item)}
                            </List.Item>
                          )
                        }
                      />
                    )
                  )}
                  <SpinnerInlineComponent
                    noBackground
                    style={{ borderRadius: 8 }}
                    sizeSpin={'small'}
                    isLoading={isLoadingTaskList}
                    tip={'Đang tải dữ liệu...'}
                  />
                </CardWrapper>

                <CardWrapper
                  title={<b>Công việc chưa hoàn thành</b>}
                  extra={
                    <a onClick={onToggleIncompleteWork(true)}>Xem tất cả</a>
                  }>
                  {isLoadingWorkList ? (
                    <BoxChartLoading />
                  ) : (
                    workStore.incompleteWorkList && (
                      <StatisticList
                        dataSource={workStore.incompleteWorkList.slice(0, 3)}
                        renderItem={item => (
                          <List.Item key={item.id} title={item.title}>
                            <a href={`works/view/${item.code}`} target="_blank">
                              {item.title}
                            </a>
                          </List.Item>
                        )}
                      />
                    )
                  )}
                  <SpinnerInlineComponent
                    noBackground
                    style={{ borderRadius: 8 }}
                    sizeSpin={'small'}
                    isLoading={isLoadingWorkList}
                    tip={'Đang tải dữ liệu...'}
                  />
                </CardWrapper>
              </ColWrapper>

              <Col span={14}>
                <WorkScheduledCard
                  title={<b>Lịch cơ quan</b>}
                  extra={
                    <div>
                      <Tooltip title={'Tuần trước'}>
                        <Button
                          onClick={() => handleChangeWeek(days, 'prev')}
                          icon={<LeftOutlined />}
                        />
                      </Tooltip>
                      <DatePicker
                        allowClear={false}
                        onChange={date => handleChangeWeek(date, 'week')}
                        picker="week"
                        placeholder={'Chọn tuần'}
                        format={'Tuần w, YYYY'}
                        value={moment(days[0])}
                        style={{
                          height: 32,
                          width: 120,
                          margin: '0 10px 0 10px',
                        }}
                      />
                      <Tooltip title={'Tuần sau'}>
                        <Button
                          onClick={() => handleChangeWeek(days, 'next')}
                          icon={<RightOutlined />}
                        />
                      </Tooltip>
                    </div>
                  }>
                  <Tabs
                    defaultActiveKey={days
                      .map(day => day.getDay())
                      .findIndex(day => day === moment().day())
                      .toString()}
                    size={'small'}>
                    {days.map(day => {
                      let dayIndex = days.indexOf(day)
                      let strDay = moment(day).format(DD_MM)
                      let renderDayTab = () => {
                        if (dayIndex !== 6) {
                          return (
                            <DayTab>
                              <p>{`Thứ ${dayIndex + 2}`}</p>
                              <p> {`(${strDay})`}</p>
                            </DayTab>
                          )
                        } else {
                          return (
                            <DayTab>
                              <p>Chủ nhật</p>
                              <p> {`(${strDay})`}</p>
                            </DayTab>
                          )
                        }
                      }
                      return (
                        <TabPane tab={renderDayTab()} key={dayIndex}>
                          <ItemCalendarContent>
                            {workScheduleOfDay(strDay).length !== 0 ? (
                              workScheduleOfDay(strDay).map(workScheduled => {
                                return (
                                  <WorkScheduledItem
                                    key={workScheduled.schedule_code}>
                                    {getShipScheduleContent(workScheduled)
                                      .slice(0, 4)
                                      .map(item => item)}

                                    <Transition
                                      in={isOpenWorkScheduleDetail(
                                        workScheduled.schedule_code
                                      )}
                                      mountOnEnter={true}
                                      unmountOnExit={true}
                                      timeout={500}>
                                      {state => (
                                        <div
                                          style={{
                                            ...defaultStyle,
                                            ...transitionStyles[state],
                                          }}>
                                          {getShipScheduleContent(workScheduled)
                                            .slice(
                                              4,
                                              getShipScheduleContent(
                                                workScheduled
                                              ).length
                                            )
                                            .map(item => item)}
                                        </div>
                                      )}
                                    </Transition>

                                    <div
                                      style={{
                                        display: 'flex',
                                        gap: '32px',
                                        justifyContent: 'flex-end',
                                      }}>
                                      {isOpenWorkScheduleDetail(
                                        workScheduled.schedule_code
                                      ) && (
                                        <a
                                          href={`company-work-schedule/view/${workScheduled.schedule_code}`}
                                          target="_blank">
                                          Chi tiết
                                        </a>
                                      )}
                                      <a
                                        onClick={onToggleWorkScheduleDetail(
                                          workScheduled.schedule_code
                                        )}>
                                        {!isOpenWorkScheduleDetail(
                                          workScheduled.schedule_code
                                        )
                                          ? 'Mở rộng'
                                          : 'Thu gọn'}
                                      </a>
                                    </div>
                                  </WorkScheduledItem>
                                )
                              })
                            ) : !isLoadingCalendar ? (
                              <EmptyContent description={'Không có sự kiện!'} />
                            ) : (
                              <div style={{ height: 106 }} />
                            )}
                            <SpinnerInlineComponent
                              sizeSpin={'small'}
                              isLoading={isLoadingCalendar}
                              noBackground
                              tip={'Đang tải lịch cơ quan...'}
                            />
                          </ItemCalendarContent>
                        </TabPane>
                      )
                    })}
                  </Tabs>
                </WorkScheduledCard>
              </Col>
            </Row>
          </div>

          {statistic && (
            <UnreadDocModal
              title="Văn bản đến mới"
              key={'unread_incoming'}
              items={statistic.unread_incoming}
              isVisible={isShowUnreadIncoming}
              onClose={onCloseUnreadIncoming}
            />
          )}
          {statistic && (
            <UnreadDocModal
              title="Văn bản đi mới"
              key={'unread_outgoing'}
              items={statistic.unread_outgoing}
              isVisible={isShowUnreadOutgoing}
              onClose={onCloseUnreadOutgoing}
            />
          )}
          {workStore.incompleteWorkList && (
            <IncompleteListModal
              modalTitle="Công việc chưa hoàn thành"
              listType="WORK"
              items={workStore.incompleteWorkList}
              isVisible={isShowIncompleteWork}
              onClose={onToggleIncompleteWork(false)}
            />
          )}
        </DashboardLayout>
      ) : (
        loginPage
      )}
    </div>
  )
}

export default memo(
  inject(
    'workScheduleStore',
    'loadingAnimationStore',
    'commonStore',
    'statisticStore',
    'fileStore',
    'authenticationStore',
    'accountStore',
    'userStore',
    'companyStore',
    'notificationStore',
    'commandStore',
    'taskManagementStore',
    'taskManagementCommentStore',
    'moduleStore',
    'taskStore',
    'workStore',
    'aclStore',
  )(observer(DashboardPageV2)),
)
