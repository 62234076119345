import styled from 'styled-components'
import { blue } from '../../color'
import { Space } from 'antd'

export const FormActionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 30px 0;
`

export const ButtonsWrapper = styled(Space)``
export const SelectList = styled.div`
  max-height: 80px;
  overflow-y: auto;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .tag-selected {
    border-radius: 35px;
    padding: 3px 8px 3px 4px;
    display: flex;
    margin-right: 0;
    align-items: center;
  }
`

export const SelectTitle = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PopoverList = styled.ul`
  min-width: 300px;
  list-style: none;
  padding-left: 0;

  .popover-head {
    display: flex;
    justify-content: space-between;
  }

  li {
    margin-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`

export const PopoverTitle = styled.div`
  height: 32px;
  color: #fff;
  margin: -5px -16px;
  padding: 5px 16px;
  background-color: ${blue};
  text-align: center;
`

export const DocumentList = styled.ul`
  list-style: none;
  padding-left: 0;
`
