import React from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import accountStore from '../../stores/accountStore'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

const RefreshTrucButton = props => {
  const { authenticationStore, loadingAnimationStore } = props

  const refreshTrucAccount = async () => {
    loadingAnimationStore.showSpinner(true)

    // console.log('refreshTrucAccount')
    authenticationStore.logoutTruc()
    await accountStore.getCurrentUserAccount()

    loadingAnimationStore.showSpinner(false)
    message.success('Đã làm mới lại liên kết tới trục')
  }

  return (
    <Button style={{ marginLeft: 14 }} onClick={refreshTrucAccount}>
      <ReloadOutlined />
      Cấu hình lại liên kết trục
    </Button>
  )
}

RefreshTrucButton.propTypes = {}

export default withRouter(
  inject(
    'loadingAnimationStore',
    'authenticationStore'
  )(observer(RefreshTrucButton))
)
