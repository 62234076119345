import { action, observable } from 'mobx'
import { CLOUD_DOCUMENT } from '../constants'
import { CommandRequest } from '../requests/CommandRequest'
import { vimcTaiLieuUrl } from '../config'

class CommandStore {
  @observable sideMenuCounter = {
    work: 0,
    proposal: 0,
  }
  /** Command list */
  @observable commandList = []

  getSideMenuCounter = async (types) => {
    let response = await CommandRequest.getSideMenuCounter(types)
    this.sideMenuCounter = response.data
  }

  @action getCommandList = () => {
    return new Promise((resolve, reject) => {
      CommandRequest.getCommandList()
        .then(response => {
          response.data.forEach(item => {
            if (item.code === CLOUD_DOCUMENT) {
              item.url = vimcTaiLieuUrl
            }
          })

          this.commandList = response.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /** Clear store */
  @action clearStore = () => {
    this.commandList.length = 0
  }
}

export default new CommandStore()
