import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import PDFViewer from '../../../components/PDFViewer/PDFViewer'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import {
  EmptyText,
  TitleContentBox,
  WordBreak,
} from '../../../components/Common/CellText'
import PopupHandleDocument from './PopupHandleDocumentIncoming'
import InternalDocumentComment from '../InternalDocumentComment'
import { StatusTag } from '../../../components/Common/StatusTag'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
//Layout
import DashboardLayout from '../../../layouts/DashboardLayout'
// Styled Component
import {
  BlockLeft,
  BlockRight,
  DocumentContentWrapper,
  DocumentInfoList,
  HeadingButtonWrapper,
} from '../../../layouts/InternalDocumentDetailLayout/InternalDocumentDetailLayoutStyled'
import { UserListWrapper } from './InternalDocumentIncomingDocDetailPageStyled'
// Ant design
import { Dropdown, Form, Menu, message, Modal, Tag } from 'antd'
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  MoreOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
// PDF Viewer
import { useMediaQuery } from 'react-responsive'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import {
  ACL_ACTION_TYPE,
  DATE_FORMAT_DEFAULT,
  DOCUMENT_TYPE,
  TYPE_STATUS,
} from '../../../constants'
import { blue } from '../../../color'
import utils from '../../../utils'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'
import moment from 'moment'
import queryString from 'query-string'
import LinkedWork from '../../../components/Common/Linked/LinkedWork'
import LinkedTask from '../../../components/Common/Linked/LinkedTask'
import PopupPhanPhatDocument from '../PopupPhanPhatDocument'
import AccessControlAction from '../../../components/AccessControlAction'

const { confirm } = Modal

const InternalDocumentIncomingIncomingDocDetailPage = props => {
  const [form] = Form.useForm()

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const {
    match,
    internalDocumentStore,
    loadingAnimationStore,
    history,
    notificationStore,
    selectPeopleStore,
    aclStore,
  } = props

  const { documentId } = match.params
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore

  const {
    selectedInternalDocumentIncoming,
    internalDocumentCommentList,
    handlerAssignList,
    combinerAssignList,
    followerAssignList,
  } = internalDocumentStore

  const {
    authority_issued_id,
    agency_issued,
    date_issued,
    incoming_date,
    incoming_number,
    document_number,
    signer,
    title,
    attachments,
    linkedTask,
    linkedWork,
    end_date,
    start_date,
    document_status,
  } = selectedInternalDocumentIncoming

  const [
    isVisiblePopupHandleDocument,
    setIsVisiblePopupHandleDocument,
  ] = useState(false)
  const [
    isVisiblePopupPhanPhatDocument,
    setIsVisiblePopupPhanPhatDocument,
  ] = useState(false)
  const [isLoadingComment, setIsLoadingComment] = useState(false)
  const [attachmentForView, setAttachmentForView] = useState()
  const [commentLeaderList, setCommentLeaderList] = useState([])

  useEffect(() => {
    if (handlerAssignList.length === 0) return
    const commentList = toJS(internalDocumentCommentList).filter(
      cmt => !cmt.deleted && cmt.user_name === handlerAssignList[0].id
    )
    setCommentLeaderList(commentList)
  }, [internalDocumentCommentList, handlerAssignList])

  const renderAuthorityIssuedList = (authorityIssuedId, agencyIssued) => {
    const authorityList = []
    if (authorityIssuedId) {
      authorityList.push(authorityIssuedId.name)
    }
    if (agencyIssued && agencyIssued !== authorityIssuedId?.name) {
      authorityList.push(agencyIssued)
    }
    if (authorityIssuedId || agencyIssued) {
      return authorityList.join(', ')
    }
    return <EmptyText>Không có CQ ban hành.</EmptyText>
  }

  useEffect(() => {
    if (!attachments) return
    setAttachmentForView(
      attachments.find(file =>
        ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))
      )
    )
  }, [attachments])

  const renderAttachment = useMemo(
    () => (
      <PDFViewer
        fileId={attachmentForView?.id}
        customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
        fileType={utils.getExtensionFile(attachmentForView?.name)}
      />
    ),
    [attachmentForView]
  )

  const handleDeleteDocument = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await internalDocumentStore.deleteIncomingInternalDocumentById(documentId)
      history.push('/internal-document/incoming-document')
      message.success('Xóa văn bản thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn xóa văn bản này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteDocument()
      },
    })
  }

  const menuHandleDocument = (
    <Menu>
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.edit__INCOMING) && (
        <Menu.Item
          onClick={() =>
            history.push(`/internal-document/incoming-document/${documentId}`)
          }
          icon={<EditOutlined style={{ color: blue }} />}>
          <span style={{ color: blue }}>Chỉnh sửa văn bản</span>
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.xuly__INCOMING) && (
        <Menu.Item
          onClick={() => setIsVisiblePopupHandleDocument(true)}
          icon={<FormOutlined style={{ color: blue }} />}>
          <span style={{ color: blue }}>Xử lý văn bản</span>
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.tao_nv_tu_vb__INCOMING
      ) && (
        <Menu.Item
          onClick={() =>
            history.push(
              `/mission/create?document_code=${documentId}&title=${title}&type=INCOMING`
            )
          }
          icon={<ProfileOutlined style={{ color: blue }} />}>
          <span style={{ color: blue }}>Tạo nhiệm vụ từ văn bản</span>
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.tao_cv_tu_vb__INCOMING
      ) && (
        <Menu.Item
          onClick={() => {
            console.log('setIsVisiblePopupPhanPhatDocument')
            setIsVisiblePopupPhanPhatDocument(true)
          }}
          icon={<SolutionOutlined style={{ color: blue }} />}>
          <span style={{ color: blue }}>Tạo công việc từ văn bản</span>
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.delete__INCOMING) && (
        <Menu.Item danger onClick={showConfirm} icon={<DeleteOutlined />}>
          Xóa văn bản
        </Menu.Item>
      )}
    </Menu>
  )

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await internalDocumentStore.getInternalDocumentIncomingById(documentId)
      } catch (err) {
        history.push('/internal-document/incoming-document')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      form.resetFields()
      internalDocumentStore.clearSelectedInternalDocumentIncoming()
      internalDocumentStore.clearAssignList()
      notificationStore.clearNotificationType()
      selectPeopleStore.clearSelected()
    }
  }, [documentId])

  return (
    <DashboardLayout>
      <Helmet>
        <title>{`${title} | VIMC Quản lý VB nội bộ`}</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={title}
        showTitle={true}
        status={StatusTag(
          TYPE_STATUS.INTERNAL_DOCUMENT_INCOMING,
          document_status
        )}>
        <HeadingButtonWrapper>
          <Dropdown overlay={menuHandleDocument} trigger={['click']}>
            <span className={'btn-style'}>
              <MoreOutlined style={{ color: blue }} />
            </span>
          </Dropdown>
        </HeadingButtonWrapper>
      </PageTitle>
      <DocumentContentWrapper>
        <BlockLeft>{renderAttachment}</BlockLeft>
        <BlockRight>
          <ContentBlockWrapper>
            <TitleContentBox>Thông tin chung</TitleContentBox>
            <DocumentInfoList>
              <dt>Số hiệu</dt>
              <dd>{document_number}</dd>
              <dt>Số đến</dt>
              <dd>{incoming_number}</dd>
              <dt>Ngày văn bản</dt>
              <dd>
                {date_issued ? (
                  <>
                    <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                    <span>
                      {moment(date_issued).format(DATE_FORMAT_DEFAULT)}
                    </span>
                  </>
                ) : (
                  <EmptyText>Không có ngày văn bản.</EmptyText>
                )}
              </dd>
              <dt>Ngày đến</dt>
              <dd>
                {incoming_date ? (
                  <>
                    <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                    <span>
                      {moment(incoming_date).format(DATE_FORMAT_DEFAULT)}
                    </span>
                  </>
                ) : (
                  <EmptyText>Không rõ ngày đến.</EmptyText>
                )}
              </dd>
              <dt>Người ký</dt>
              <dd>
                {signer ? signer : <EmptyText>Không có người ký.</EmptyText>}
              </dd>
              <dt>CQ ban hành</dt>
              <dd>
                {WordBreak(
                  renderAuthorityIssuedList(authority_issued_id, agency_issued)
                )}
              </dd>
              <dt>Tài liệu đính kèm</dt>
              <dd>
                <div>
                  {attachments ? (
                    attachments.map(file => (
                      <FileItem
                        key={file.id}
                        file_id={file.id}
                        file_name={file.name}
                        file_type={utils.getExtensionFile(file.name)}
                      />
                    ))
                  ) : (
                    <EmptyText>Không có tài liệu đính kèm</EmptyText>
                  )}
                </div>
              </dd>
              <dt>Liên kết nhiệm vụ</dt>
              <dd>
                <LinkedTask linkedTaskList={linkedTask} />
              </dd>
              <dt>Liên kết công việc</dt>
              <dd>
                <LinkedWork linkedWorkList={linkedWork} />
              </dd>
            </DocumentInfoList>
          </ContentBlockWrapper>
          <div style={{ marginBottom: 15 }} />
          <ContentBlockWrapper>
            <TitleContentBox>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <span>Xử lý văn bản</span>
                <AccessControlAction
                  aclActionType={ACL_ACTION_TYPE.phanphat__INCOMING}>
                  <Tag
                    onClick={() => setIsVisiblePopupPhanPhatDocument(true)}
                    style={{
                      borderRadius: 10,
                      cursor: 'pointer',
                      marginRight: 0,
                    }}
                    color={'blue'}>
                    Phân phát
                  </Tag>
                </AccessControlAction>
              </div>
            </TitleContentBox>
            <UserListWrapper>
              <div style={{ display: 'flex', flexGrow: 1 }}>
                <b>Lãnh đạo xử lý:</b>
                {handlerAssignList.length !== 0 ? (
                  <PopoverWatchTime
                    userList={handlerAssignList}
                    title={'Lãnh đạo xử lý'}
                  />
                ) : (
                  <EmptyText>Không có lãnh đạo xử lý.</EmptyText>
                )}
              </div>
            </UserListWrapper>
            <UserListWrapper>
              <div style={{ display: 'flex', flexGrow: 1 }}>
                <b>Người xử lý:</b>
                {combinerAssignList.length !== 0 ? (
                  <PopoverWatchTime
                    userList={combinerAssignList}
                    title={'Danh sách người xử lý'}
                  />
                ) : (
                  <EmptyText>Không có người xử lý.</EmptyText>
                )}
              </div>
            </UserListWrapper>
            <UserListWrapper>
              <div style={{ display: 'flex', flexGrow: 1 }}>
                <b>Phối hợp/ Theo dõi:</b>
                {followerAssignList.length !== 0 ? (
                  <PopoverWatchTime
                    userList={followerAssignList}
                    title={'Danh sách người phối hợp/ theo dõi'}
                  />
                ) : (
                  <EmptyText>Không có danh sách phối hợp/ theo dõi.</EmptyText>
                )}
              </div>
            </UserListWrapper>
            <div>
              <div style={{ marginBottom: 10 }}>
                <b style={{ minWidth: 120, display: 'inline-block' }}>
                  Ngày bắt đầu:
                </b>
                <span>
                  {(start_date || date_issued) && (
                    <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                  )}
                  {start_date || date_issued ? (
                    <span>
                      {moment(start_date || date_issued).format(
                        DATE_FORMAT_DEFAULT
                      )}
                    </span>
                  ) : (
                    <EmptyText>Không rõ.</EmptyText>
                  )}
                </span>
              </div>
              <div style={{ marginBottom: 10 }}>
                <b style={{ minWidth: 120, display: 'inline-block' }}>
                  Ngày kết thúc:
                </b>
                <span>
                  {end_date ? (
                    <>
                      <CalendarOutlined
                        style={{ color: blue, marginRight: 4 }}
                      />
                      <span>
                        {moment(end_date).format(DATE_FORMAT_DEFAULT)}
                      </span>
                    </>
                  ) : (
                    <EmptyText>Không rõ.</EmptyText>
                  )}
                </span>
              </div>
            </div>
          </ContentBlockWrapper>
          <div style={{ marginBottom: 15 }} />
          <ContentBlockWrapper style={{ position: 'relative' }}>
            <InternalDocumentComment
              documentId={documentId}
              setIsLoadingComment={bool => setIsLoadingComment(bool)}
            />
            <SpinnerInlineComponent
              sizeSpin={'small'}
              isLoading={isLoadingComment}
            />
          </ContentBlockWrapper>
        </BlockRight>
      </DocumentContentWrapper>

      <PopupHandleDocument
        isVisiblePopupHandleDocument={isVisiblePopupHandleDocument}
        handleCancelHandleDocument={() =>
          setIsVisiblePopupHandleDocument(false)
        }
        handleCloseHandleDocument={() => setIsVisiblePopupHandleDocument(false)}
      />
      <PopupPhanPhatDocument
        typeDocument={DOCUMENT_TYPE.INCOMING}
        selectedInternalDocument={selectedInternalDocumentIncoming}
        commentLeaderList={commentLeaderList}
        isVisiblePopupPhanPhatDocument={isVisiblePopupPhanPhatDocument}
        handleClosePopupPhanPhatDocument={() =>
          setIsVisiblePopupPhanPhatDocument(false)
        }
      />
    </DashboardLayout>
  )
}

InternalDocumentIncomingIncomingDocDetailPage.propTypes = {}

export default inject(
  'commonStore',
  'internalDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'departmentStore',
  'staffStore',
  'fileStore',
  'notificationStore',
  'selectPeopleStore',
  'aclStore',
)(observer(InternalDocumentIncomingIncomingDocDetailPage))
