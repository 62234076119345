import { Drawer, message, Switch } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import AccountEditingDrawer from '../../../../components/UserPermissionPanel/AccountEditingDrawer'

const ConnectedTrucAccountUserConfigPanel = props => {
  const {
    drawerVisible,
    commandStore,
    onClose,
    userStore,
    authenticationStore,
    loadingAnimationStore,
  } = props

  const { selectedUser } = userStore
  const selectedUserCommandCodeArr =
    selectedUser &&
    selectedUser.commands &&
    selectedUser.commands.map(command => command.code)
  const [showAccountManager, setShowAccountManager] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(undefined)

  useEffect(() => {
    if (
      selectedUserCommandCodeArr &&
      selectedUserCommandCodeArr.includes(connecedTrucCommand?.code)
    ) {
      setSelectedAccount(connecedTrucCommand)
    }
  }, [selectedUserCommandCodeArr])

  const handleCloseAccountManager = () => {
    setShowAccountManager(false)
    props.onClose()
  }

  const handleShowAccountManager = command => {
    setShowAccountManager(true)
    setSelectedAccount(command)
  }

  const connecedTrucCommand = commandStore.commandList.find(
    command => command.name === 'Trục liên thông'
  )

  const handleChangeCommand = (status, commandCode, commandName) => {
    if (status) {
      handleShowAccountManager(connecedTrucCommand)
    } else {
      handleCloseAccountManager()
    }
    let updatedStatusText
    const commandObj = {
      code: commandCode,
      name: commandName,
    }
    const selectedCommandArr = selectedUser && selectedUser.commands
    if (
      selectedCommandArr &&
      selectedCommandArr.findIndex(command => command.code === commandCode) ===
        -1
    ) {
      userStore.updateSelectedUser('commands', [
        ...selectedCommandArr,
        commandObj,
      ])
      updatedStatusText = 'kích hoạt'
    } else {
      userStore.updateSelectedUser(
        'commands',
        selectedCommandArr.filter(command => command.code !== commandCode)
      )
      updatedStatusText = 'tắt'
    }
    loadingAnimationStore.showSpinner(true)
    userStore
      .updateUserCommands(
        selectedUser.code,
        selectedUser.commands.map(command => command.code)
      )
      .then(() => {
        authenticationStore
          .checkCurrentUser()
          .then(() => {
            localStorage.removeItem('jwtTruc')
          })
          .then(() => {
            loadingAnimationStore.showSpinner(false)
            message.success(`Đã ${updatedStatusText} dịch vụ ${commandName}!`)
          })
          .catch(() => {
            loadingAnimationStore.showSpinner(false)
          })
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  return (
    <>
      <Drawer
        title={
          <div style={{ display: 'flex', gap: '12px' }}>
            <span>Trục liên thông</span>
            <Switch
              onChange={status =>
                handleChangeCommand(
                  status,
                  connecedTrucCommand?.code,
                  connecedTrucCommand?.name
                )
              }
              checked={
                selectedUserCommandCodeArr &&
                selectedUserCommandCodeArr.includes(connecedTrucCommand?.code)
              }
            />
          </div>
        }
        placement={'left'}
        visible={drawerVisible}
        width={400}
        onClose={onClose}>
        {(showAccountManager ||
          (selectedUserCommandCodeArr &&
            selectedUserCommandCodeArr.includes(
              connecedTrucCommand?.code
            ))) && (
          <AccountEditingDrawer
            selectedAccount={selectedAccount}
            drawerVisible={showAccountManager}
            onClose={handleCloseAccountManager}
          />
        )}
      </Drawer>
    </>
  )
}

export default inject(
  'userStore',
  'authenticationStore',
  'loadingAnimationStore',
  'commandStore'
)(observer(ConnectedTrucAccountUserConfigPanel))
