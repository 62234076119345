import React from 'react'
import { CalendarOutlined, CopyOutlined } from '@ant-design/icons'
import { blue, green } from '../../color'
import moment from 'moment'
import { DATE_FORMAT_SLASH, TASK_DOCUMENT_TYPE } from '../../constants'
import { EmptyText, WordBreak } from '../../components/Common/CellText'
import { DocumentList } from './MissionDetailPageStyled'
import { toJS } from 'mobx'

const MissionDetailGeneralInfo = props => {
  const { contentTask } = props

  const incomingDocument = contentTask
    ? toJS(contentTask)?.van_bans.filter(
        doc => doc.loai_van_ban === TASK_DOCUMENT_TYPE.INCOMING
      )
    : []
  const outgoingDocument = contentTask
    ? toJS(contentTask)?.van_bans.filter(
        doc => doc.loai_van_ban === TASK_DOCUMENT_TYPE.OUTGOING
      )
    : []

  return (
    <>
      <dt>Thời gian</dt>
      <dd>
        <div>
          <span style={{ marginRight: 15 }}>
            <CalendarOutlined style={{ color: blue, marginRight: 2 }} />
            <span style={{ marginRight: 6 }}>Ngày giao:</span>
            <span style={{ fontWeight: 500 }}>
              {`${moment(contentTask?.ngay_bat_dau).format(DATE_FORMAT_SLASH)}`}
            </span>
          </span>
          <span>
            <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
            <span style={{ marginRight: 6 }}>Ngày hoàn thành:</span>
            {contentTask?.ngay_ket_thuc ? (
              <span style={{ fontWeight: 500 }}>
                {`${moment(contentTask?.ngay_ket_thuc).format(
                  DATE_FORMAT_SLASH
                )}`}
              </span>
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </span>
        </div>
      </dd>
      <dt>Nội dung</dt>
      <dd>
        {contentTask?.noi_dung ? (
          WordBreak(contentTask?.noi_dung, true)
        ) : (
          <EmptyText>Không có nội dung</EmptyText>
        )}
      </dd>
      <dt>Ghi chú</dt>
      <dd>
        {contentTask?.ghi_chu ? (
          WordBreak(contentTask?.ghi_chu, true)
        ) : (
          <EmptyText>Không có ghi chú</EmptyText>
        )}
      </dd>
      <dt>Văn bản</dt>
      <dd>
        <DocumentList>
          {contentTask?.van_bans.length === 0 && (
            <EmptyText>Không có văn bản</EmptyText>
          )}
          {incomingDocument.length !== 0 && (
            <p style={{ marginBottom: 5 }}>Văn bản đến:</p>
          )}
          {incomingDocument.map(el => (
            <li
              key={el.code_van_ban}
              onClick={() =>
                window.open(
                  `/internal-document/incoming-document/view/${el.code_van_ban}`,
                  '_blank'
                )
              }>
              <CopyOutlined style={{ color: green, marginRight: 8 }} />
              {`${el.document_number} - ${el.ten_van_ban}`}
            </li>
          ))}
          {outgoingDocument.length !== 0 && (
            <p style={{ marginBottom: 5 }}>Văn bản đi:</p>
          )}
          {outgoingDocument.map(el => (
            <li
              key={el.code_van_ban}
              onClick={() =>
                window.open(
                  `/internal-document/outgoing-document/view/${el.code_van_ban}`,
                  '_blank'
                )
              }>
              <CopyOutlined style={{ color: blue, marginRight: 8 }} />
              {`${el.document_number} - ${el.ten_van_ban}`}
            </li>
          ))}
        </DocumentList>
      </dd>
    </>
  )
}

MissionDetailGeneralInfo.propTypes = {}

export default MissionDetailGeneralInfo
